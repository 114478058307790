export default function Error404() {
    return (
        <div className="error_container">
            <div>
                <h2>404</h2>
            </div>
            <div>
                <a href="/">back to homepage</a>
            </div>
        </div>
    );
}
