import { SET_USER } from ".";
import AuthRequest from "src/requests/authRequest";
import Cookies from "js-cookie";
import { dateDiff } from "@utils/common";
import { TOKEN } from "src/constants";

export function login(payload) {
    return async (dispatch) => {
        const response = await AuthRequest.login(payload);
        const expired = dateDiff(response.data.token_expires_at);
        Cookies.set(TOKEN, response.data.token, { expires: expired + 1 });
    };
}

export function logout() {}

export function resetPassword() {}

export function getCurrentUser() {
    return async (dispatch) => {
        const response = await AuthRequest.getUser();
        dispatch(SET_USER(response.data));
        return response.data;
    };
}

export function updateUserProfile(payload) {
    return async (dispatch) => {
        const response = await AuthRequest.updateUser(payload);
        dispatch(SET_USER(response.data));
        return response;
    };
}
