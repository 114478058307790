
export default function Time(setting, callBack) {
    const { minute, second } = setting
    return (
        <>
            <div>
                <label htmlFor="time left" className="time-left">Time left:</label>
                <label htmlFor="Time left">{minute}m {second}s</label>
            </div>
        </>
    )
}
