import { Button } from "@components/index";
import { useNotifyBarContext } from "@contexts/notifyBarContext";
import { fetchQuizDetails, sendQuiz, submitQuiz } from "@stores/quiz/thunk";
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import Instruction from "./Instruction";
import QuizItem from "./QuizItem";
import QuizResult from "./QuizResult";
import Timer from "./Timer";

export default function Index() {
    const dispatch = useDispatch()
    const { quizId } = useParams()
    const data = useSelector((state) => state?.quiz?.quiz)

    //notify
    const { showNotify } = useNotifyBarContext()
    //local state
    const [form, setForm] = useState(null)
    const [result, setResult] = useState(null)
    const [submitted, setSubmitted] = useState(null)
    const [resultClose, setResultClose] = useState(null)
    const [loading, setLoading] = useState(false)

    //prefix option name
    const options = data?.questions

    //init fetch quiz
    useEffect(() => {
        fetchQuiz()
        // eslint-disable-next-line
    }, [resultClose])

    //on click send data back to quiz
    useEffect(() => {
        if (form && !data?.is_submitted) {
            dispatch(sendQuiz(quizId, form))
        }
        // eslint-disable-next-line
    }, [form])

    async function fetchQuiz() {
        try {
            await dispatch(fetchQuizDetails(quizId))
        } catch (e) {
            showNotify('error', 'Technical Error')
        }
    }

    //when item is click
    function handleForm(evt) {
        const { name, value } = evt.target;
        setForm(prev => ({
            ...prev,
            question: name,
            answer: value,
            quiz_student: quizId
        }));
    }
    //handle when button submit is click
    async function handleSubmit(evt) {
        evt.preventDefault()
        try {
            setLoading(true)
            const response = await dispatch(submitQuiz(quizId))
            if (response.data) {
                setResult(response.data)
                setSubmitted(true)
            }
        } catch (error) {
            setLoading(false)
        }
    }


    return (
        <div className="quiz">
            <Instruction data={data} />
            {
                !data?.is_submitted && !!data?.remaining && <Timer remaining={data?.remaining} freezeTime={submitted} />
            }
            {
                data?.questions &&
                <>
                    <div className="quiz__wrap__table-wrap">
                        <form onSubmit={handleSubmit}>
                            {
                                options?.map((el, index) => (
                                    <QuizItem key={el.id + index} handleSetForm={handleForm} data={el} question_id={index + 1} is_submitted={data.is_submitted } />
                                ))
                            }
                            {!data?.is_submitted &&
                                <div className="quiz__submit-btn">
                                    <div className={loading ? "loading-btn" : ""} disabled={loading} id="submit">
                                        <Button is_loading={loading} button_type='submit' is_disabled={false} name='SUBMIT' />
                                    </div>
                                    {
                                        !data?.is_submitted &&
                                        <Popup
                                            open={submitted}
                                            modal
                                            onClose={() => setResultClose(false)}
                                            closeOnDocumentClick={true}>
                                            <QuizResult result={result} />
                                            <div className="back-btn">
                                                <button onClick={()=>setSubmitted(false)} className='btn'>Check Result</button>
                                            </div>
                                        </Popup>
                                    }
                                </div>
                            }
                        </form>
                    </div>
                </>
            }
        </div>
    )
}