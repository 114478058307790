import React from 'react'
import Success from '@assets/img/quiz_success.svg'
import Fail from '@assets/img/quiz_fail.svg'
import { useSelector } from 'react-redux'

export default function QuizResult({ result }) {
    const { user } = useSelector((state) => state.auth)
    const resultImg = { success: Success, fail: Fail }
    const resultStauts = { success: "Congratulations", fail: "Oh no! Don't Give Up" }
    const resultDetail = { success: "You have successfully completed", fail: "Next Attempt Is your Lucky One!" }
    const resultScore = { success: "Pass ", fail: "Fail " }

    return (
        <div className='quiz-result'>
            <div>
                <img src={result?.is_pass ? resultImg.success : resultImg.fail} alt="" height='auto' />
            </div>
                <div>
                    <h1 htmlFor="result status" className='quiz-result__title'>{result?.is_pass ? resultStauts.success : resultStauts.fail}</h1>
                </div>
                <div>
                    <p htmlFor="result detail" className='quiz-result__sub-title'>{result?.is_pass ? resultDetail.success : resultDetail.fail}</p>
                </div>
                <div>
                    <label htmlFor="course name">{user?.course?.name}</label>
                </div>
                <div className='quiz-result__score'>
                    <label htmlFor="pass">{result?.is_pass ? resultScore.success : resultScore.fail}</label>
                    <label htmlFor="score">{result?.score}/{result?.total_score}</label>
                </div>
        </div>
    )
}
