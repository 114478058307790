import { formatDateSpace } from "@utils/common";
import React from "react";

export default function VerificationPopUp(props) {
    const { data, handleBadgeClick } = props;
    function handleClickOutSide(){

    }
    return (
        <div className="vf-popup" onClick={handleClickOutSide}>
            <div className="vf-popup__dialog">
                <div className="vf-popup__dialog-header">
                    <div className="vf-popup__dialog-header-ttl">
                        Verification
                    </div>
                    <div
                        className="vf-popup__dialog-header-close"
                        onClick={() => handleBadgeClick(false)}
                    >
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fal"
                            data-icon="times"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                        >
                            <path
                                fill="currentColor"
                                d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                            ></path>
                        </svg>
                    </div>
                </div>
                <div className="vf-popup__dialog-content">
                    <ul>
                        <li>
                            <div className="vf-popup__dialog-content-ttl">
                                <span
                                    class="action-indicator action-indicator--succeeded"
                                    aria-label="verified"
                                >
                                    <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fas"
                                        data-icon="check"
                                        class="svg-inline--fa fa-check fa-w-16 "
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                                        ></path>
                                    </svg>
                                </span>
                            </div>
                            <div>
                                Issued &nbsp;on &nbsp;
                                {formatDateSpace(data?.graduation_date)}
                            </div>
                        </li>
                        <li>
                            <div className="vf-popup__dialog-content-ttl">
                                <span
                                    class="action-indicator action-indicator--succeeded"
                                    aria-label="verified"
                                >
                                    <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fas"
                                        data-icon="check"
                                        class="svg-inline--fa fa-check fa-w-16 "
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                                        ></path>
                                    </svg>
                                </span>
                            </div>
                            <div>
                                Issued &nbsp;by &nbsp;
                                {data?.extra?.base_info?.issued_by}
                            </div>
                        </li>
                        <li>
                            <div className="vf-popup__dialog-content-ttl">
                                <span
                                    class="action-indicator action-indicator--succeeded"
                                    aria-label="verified"
                                >
                                    <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fas"
                                        data-icon="check"
                                        class="svg-inline--fa fa-check fa-w-16 "
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                                        ></path>
                                    </svg>
                                </span>
                            </div>
                            <div>Issued &nbsp;to &nbsp; {data?.name}</div>
                        </li>
                        <li>
                            <div className="vf-popup__dialog-content-ttl">
                                <span
                                    class="action-indicator action-indicator--succeeded"
                                    aria-label="verified"
                                >
                                    <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fas"
                                        data-icon="check"
                                        class="svg-inline--fa fa-check fa-w-16 "
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                                        ></path>
                                    </svg>
                                </span>
                            </div>
                            <div>
                                Accepted &nbsp;on &nbsp;
                                {formatDateSpace(data?.graduation_date)}
                            </div>
                        </li>
                        <li>
                            <div className="vf-popup__dialog-content-ttl">
                                <span
                                    class="action-indicator action-indicator--succeeded"
                                    aria-label="verified"
                                >
                                    <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fas"
                                        data-icon="check"
                                        class="svg-inline--fa fa-check fa-w-16 "
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                                        ></path>
                                    </svg>
                                </span>
                            </div>
                            <div>
                                Last Updated &nbsp;
                                {formatDateSpace(data?.graduation_date)}
                            </div>
                        </li>
                        <li>
                            <div className="vf-popup__dialog-content-ttl">
                                <span
                                    class="action-indicator action-indicator--succeeded"
                                    aria-label="verified"
                                >
                                    <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fas"
                                        data-icon="check"
                                        class="svg-inline--fa fa-check fa-w-16 "
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                                        ></path>
                                    </svg>
                                </span>
                            </div>
                            <div>VERIFIED</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
