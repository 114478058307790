
export default function List() {
  

    return (
        <div className="video">
            <div className="contents">
                <div className="main">
                    <div className="main-header">
                        <h2 className="main-header__ttl">Last Watched</h2>
                    </div>
                </div>
                <div className="video-main">
                    <main className="video-contents">
                        {/* <video controls>
                            <source src={require("@assets/video/sample-video.mp4")} type="video/mp4" poster={require("@assets/video/sample-video-poster.webp")}/>
                        </video> */}
                        <h2 className="video-contents__ttl">Learning Data Science course from Tech I.S</h2>
                        <ul className="video-contents__menu">
                            <li className="video-contents__menu-item is-like">
                                <span>25 Likes</span>
                            </li>
                            <li className="video-contents__menu-item is-com">
                                <span>17 Comments</span>
                            </li>
                            <li className="video-contents__menu-item is-dl">
                                <span>Download</span>
                            </li>
                        </ul>
                    </main>
                    <aside className="video-list">
                        <h2 className="video-list__ttl">
                            The Ultimate Course Content<br />
                            of Web Development from Tech I.S
                        </h2>
                        <ul className="video-list__items">
                            <li className="video-list__item is-complete">
                                <span className="ttl">Getting started with Web Development course</span>
                                <span className="time">50/60m</span>
                            </li>
                            <li className="video-list__item is-playing">
                                <span className="ttl">Getting started with Web Development course</span>
                                <span className="time">02:30</span>
                            </li>
                            <li className="video-list__item">
                                <span className="ttl">Getting started with Web Development course</span>
                                <span className="time">02:30</span>
                            </li>
                            <li className="video-list__item">
                                <span className="ttl">Getting started with Web Development course</span>
                                <span className="time">02:30</span>
                            </li>
                            <li className="video-list__item">
                                <span className="ttl">Getting started with Web Development course</span>
                                <span className="time">02:30</span>
                            </li>
                            <li className="video-list__item">
                                <span className="ttl">Getting started with Web Development course</span>
                                <span className="time">02:30</span>
                            </li>
                            <li className="video-list__item">
                                <span className="ttl">Getting started with Web Development course</span>
                                <span className="time">02:30</span>
                            </li>
                            <li className="video-list__item">
                                <span className="ttl">Getting started with Web Development course</span>
                                <span className="time">02:30</span>
                            </li>
                            <li className="video-list__item">
                                <span className="ttl">Getting started with Web Development course</span>
                                <span className="time">02:30</span>
                            </li>
                            <li className="video-list__item">
                                <span className="ttl">Getting started with Web Development course</span>
                                <span className="time">02:30</span>
                            </li>
                            <li className="video-list__item">
                                <span className="ttl">Getting started with Web Development course</span>
                                <span className="time">02:30</span>
                            </li>
                            <li className="video-list__item">
                                <span className="ttl">Getting started with Web Development course</span>
                                <span className="time">02:30</span>
                            </li>
                            <li className="video-list__item">
                                <span className="ttl">Getting started with Web Development course</span>
                                <span className="time">02:30</span>
                            </li>
                        </ul>
                    </aside>
                </div>

            </div>
        </div>
    )
}