import Section from "./components/Section";
import Aside from "./components/Aside";
import { fetchSection } from "@stores/home/thunk";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useProgressBarContext } from "@contexts/progressBarContext";
import CustomSelect from "@components/input/CustomSelect";
import {
    getCurrentCourseState,
    isEmpty,
    updateCurrentCourseState,
} from "@utils/common";
import { setUserActivity } from "@stores/activity/thunk";

export default function Home() {
    const data = useSelector((state) => state?.home?.section);
    const [errorDetail, setErrorDetail] = useState("");
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const { showProgress, completeProgress } = useProgressBarContext();
    //search
    const [search, setSearch] = useState("");
    const [currentSelectedCourse, setCurrentSelectedCourse] = useState(null);

    useEffect(() => {
        if (!isEmpty(user) && user?.courses.length > 0) {
            try {
                const data = getCurrentCourseState();
                getSection(data);
                setCurrentSelectedCourse(data?.name);
            } catch {
                getSection(user?.courses[0]);
                setCurrentSelectedCourse(user?.courses[0].name);
            }
        } else {
            setCurrentSelectedCourse("No course");
            completeProgress();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (isEmpty(data)) {
            showProgress();
        } else {
            completeProgress();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    function handleSearch(el) {
        const keyword = search.toLowerCase().trim();
        const section = el.name.toLowerCase().includes(keyword);

        if (keyword.length === 0) return true;
        if (section) return section;
        let temp = false;
        el.lessons.forEach((element) => {
            if (element.title.toLowerCase().includes(keyword)) {
                temp = true;
            }
        });
        el.quizzes.forEach((element) => {
            if (element.title.toLowerCase().includes(keyword)) {
                temp = true;
            }
        });
        return temp;
    }

    async function getSection(course) {
        updateCurrentCourseState(course);
        const filterCourseResult = {
            id: course.id,
            name: course.name,
            slug: course.slug,
        };
        dispatch(setUserActivity({ course: filterCourseResult }));
        try {
            await dispatch(fetchSection(course?.slug));
        } catch (error) {
            const detail = error.response.data.detail;
            setErrorDetail(detail);
        }
    }

    return (
        <div className="courses">
            <div className="contents">
                <main className="main">
                    <br />
                    <form className="search-form" autoComplete="off">
                        <div className="header__search">
                            <div className="header__search-keyword">
                                <img
                                    type="image"
                                    src={
                                        require("@assets/img/header-icon-search.svg")
                                            .default
                                    }
                                    width="19"
                                    height="19"
                                    alt="search icon"
                                />
                                <input
                                    className="header__search-input"
                                    value={search}
                                    onChange={(evt) =>
                                        setSearch(evt.target.value)
                                    }
                                    name="search_course"
                                    type="text"
                                    placeholder="Search Course or Lesson"
                                />
                            </div>
                            <div className="select-container">
                                <CustomSelect
                                    option={user?.courses}
                                    selectedValueParent={currentSelectedCourse}
                                    onChange={(course) => getSection(course)}
                                />
                            </div>
                        </div>
                    </form>
                    <div className="table-wrap">
                        <div className="table">
                            <ul className="table-head">
                                <li className="table-head__item is-course">
                                    Course
                                </li>
                                <li className="table-head__item is-prog">
                                    Progress
                                </li>
                                <li className="table-head__item is-date">
                                    Target Date
                                </li>
                                <li className="table-head__item is-date">
                                    Completion Date
                                </li>
                            </ul>
                            <ul className="table-data">
                                {!isEmpty(data) ? (
                                    data?.results?.map((el, index) => {
                                        return handleSearch(el) ? (
                                            <Section
                                                key={el.id}
                                                item={el}
                                                quiz={el.quizzes}
                                            />
                                        ) : (
                                            ""
                                        );
                                    })
                                ) : (
                                    <div className="no-course">
                                        <p>
                                            {!!errorDetail
                                                ? errorDetail
                                                : "No Course"}
                                        </p>
                                    </div>
                                )}
                            </ul>
                        </div>
                    </div>
                </main>
                <div className="profile-aside">
                    <div className="profile-wrapper">
                        <Aside />
                    </div>
                    {/* <div className="recommend-video">
                        <RecommendAside />
                    </div> */}
                </div>
            </div>
        </div>
    );
}
