import { NavLink } from "react-router-dom";
import menu from "./constants/menu";

export default function Drawer({ open }) {
    function checkPath(item) {
        if (item.path === "/") {
            return window.location.pathname === item.path ? true : false;
        } else {
            return window.location.pathname.includes(item.path);
        }
    }

    return (
        <div className={open ? "is-active nav" : "nav"}>
            <nav className="nav__menu">
                <ul className="nav__list">
                    {menu.map((item, index) => (
                        <li
                            className="nav__item"
                            key={index}
                        >
                            <NavLink
                                to={item.path}
                                className={({ isActive }) => {
                                    return isActive ? "is-active" : undefined;
                                }}
                            >
                                <img
                                    src={
                                        checkPath(item)
                                            ? item.icon_a
                                            : item.icon
                                    }
                                    alt=""
                                />
                                {item.label}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
}
