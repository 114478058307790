import { link } from '@utils/validation'
import React, { useEffect, useState } from 'react'

export default function InputSubmit({ name, value, handleChange, handleClose, rules, errorMessage }) {
    const [error, setError] = useState(true)
    useEffect(() => {
        if (rules === 'link') {
            setError(link(value))
        }
    }, [value, rules])
    return (
        <div className='input'>
            <input type="text"
                className='input'
                name={name}
                autoFocus
                value={value}
                onChange={(e) => handleChange(e)}
                onBlurCapture={(e) => {
                    if (error) {
                        handleClose(name)
                    }
                }}
            />
            <div className={`${error ? 'display-none' : 'text-danger text-danger__spacing'}`}>
                <span>{errorMessage}</span>
            </div>
        </div>
    )
}
