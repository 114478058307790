import { formatDate } from "@utils/common";
import { useState } from "react"
import { useNavigate } from "react-router-dom"

export default function Section({ item }) {
    const [isActive, setIsActive] = useState(false)
    const navigate = useNavigate();

    function onClick() {
        setIsActive(!isActive)
    }

    return (
        <li className="table-data__row">
            <ul className="table-data__list">
                <li className={`table-data__item is-course ${isActive ? 'is-active' : ''}`} onClick={onClick}><span>{item.name}</span></li>
                <li className="table-data__item is-prog">
                    <span className="table-data__time"></span>
                    <progress value={item.lessons_completed} max={item.lessons_total}></progress>
                    <span className="table-data__value">{`${item.lessons_completed}/${item.lessons_total}`}</span>
                </li>
                <li className="table-data__item is-date">{formatDate(item.completion_date)}</li>
                <li className="table-data__item is-date">{formatDate(item.completed_date)}</li>
            </ul>
            <div className={`table-data__row-in ${isActive ? 'is-active' : ''}`}>
                {
                    item.lessons.map((el, index) => {
                        return (
                            <ul className="table-data__list" key={el.id}>
                                <li className={`table-data__item is-course ${el.is_available ? 'is-video' : 'is-lock disabled'}`} onClick={() => navigate('/lesson/' + el.id)}><span>{el.title}</span></li>
                                <li className="table-data__item is-prog">
                                    <span className="table-data__time"></span>
                                    <progress value={el.progress} max={100}></progress>
                                    <span className="table-data__value">{el.duration}m</span>
                                    {/* <span className="table-data__info"></span> */}
                                </li>
                            </ul>
                        )
                    }
                    )
                }
                {
                    item.quizzes.map((el, index) =>
                        <ul className="table-data__list" key={el.id}>
                            <li className={`table-data__item is-course ${el.is_available ? `${el.result_type === "failed" ? 'is-fail' : 'is-quiz'}` : 'is-lock disabled'}`} onClick={() => navigate('/quiz/' + el.id)}><span>{el.title}</span></li>
                            <li className="table-data__item is-prog">
                                <span className="table-data__time"></span>
                                <progress value={el.progress} max={100}></progress>
                                <span className="table-data__value">{el.duration}m</span>
                            </li>
                        </ul>
                    )
                }
            </div>

        </li >
    )
}
