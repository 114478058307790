const { createSlice } = require("@reduxjs/toolkit");

const recommend_video = createSlice({
    name: "recommend_video",
    initialState: {
        recommend_videos: {},
        categories: {},
    },
    reducers: {
        SET_RECOMMEND_VIDEO(state, { payload }) {
            return {
                ...state,
                recommend_videos: { results: payload.recommend_videos },
                categories: { results: payload.categories },
            };
        },
        SET_RECOMMEND_VIDEO_CATEGORY(state, { payload }) {
            return {
                ...state,
                categories: { results: payload.payload },
            };
        },
        SET_RECOMMEND_VIDEO_LOAD_MORE(state, { payload }) {
            return {
                ...state,
                recommend_videos: { results: payload.recommend_videos },
            };
        },
    },
});

export const { SET_RECOMMEND_VIDEO, SET_RECOMMEND_VIDEO_CATEGORY, SET_RECOMMEND_VIDEO_LOAD_MORE } =
    recommend_video.actions;
export default recommend_video.reducer;
