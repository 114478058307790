import React from "react";
import { useNavigate } from "react-router-dom";

export default function RecommendItem({ props, is_recommend }) {
    const navigate = useNavigate();
    const video = props?.video ? props?.video : props;
    
    return (
        <div
            className="recommend-item"
            onClick={() => navigate(`/recommend/${video.id}?recommend=${is_recommend}`)}
        >
            <div className="recommend-item__image">
                <img
                    draggable={false}
                    src={video?.thumbnail ?? video?.youtube_thumbnail}
                    alt={video?.title}
                />
            </div>
            <div className="">
                <label htmlFor={video?.title} className="recommend-item__label">
                    {video.title}
                </label>
            </div>
        </div>
    );
}
