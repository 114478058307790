import React from "react";

export default function Badge(props) {
    const { icon, name, isInfo } = props;
    return (
        <div className="cf-badge">
            <button className="cf-badge__button">
                <div
                    className={
                        isInfo
                            ? "cf-badge__button-info"
                            : "cf-badge__button-verify"
                    }
                >
                    <span>{icon}</span>
                    <span>{name}</span>
                </div>
            </button>
        </div>
    );
}
