import { Time } from '@components/index';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';

export default function Timer(state) {
    const { remaining, freezeTime } = state
    const [timeLeft, setTimeLeft] = useState(remaining);

    const second = Math.floor(timeLeft % 60)
    const minute = Math.floor(timeLeft % (1000 * 60) / 60)

    useEffect(() => {
        if (timeLeft < 1 || freezeTime) {
            //trigger button submit in index.jsx
            let submit = document.getElementById('SUBMIT')
            if (submit) {
                submit.click()
            }
            return
        };
        if (timeLeft > 1) {
            const intervalId = setInterval(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        }
        // eslint-disable-next-line
    }, [timeLeft]);

    return (
        <>
            {
                // timeLeft > 1 &&
                <div className={` notify notify__timer`}>
                    <div>
                        <label className='notify__duration' htmlFor="">Duration</label>

                        <div className="notify__content">
                            <label htmlFor="contents">
                                <div className='timer'>
                                    <Time minute={minute} second={second} />
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
