import WEB_Icon from "@assets/img/certificate/FSD_-01.png";
import DS_Icon from "@assets/img/certificate/Data_Science_01.png";

// web development
const BASE_INFO_WEB = {
    title: "Full Stack Web Development",
    issued_by: "TECH I.S. Programming School",
    detail: "This badge represents a TECH I.S. graduate's skill in Web Development. Demonstrating an understanding of full-stack Web Development and prove the ability to learn languages & frameworks with minimal support. Students develop projects that serve as a learning tool to understand the workings of production applications and learn CS concepts such as Data Structures and Algorithms, making them efficient code writers. Graduates know agile development through group development & CI / CD practically.",
};
const SKILL_WEB = [
    "Adobe XD",
    "Algorithms",
    "API",
    "CSS",
    "Data Structure",
    "Django",
    "Full-Stack Development",
    "Git",
    "GitHub",
    "Heroku Deployment",
    "HTML5",
    "JavaScript",
    "MySQL",
    "Portfolio",
    "Python",
    "ReactJS",
    "Redux",
    "REST API",
    "Security",
    "SQL",
];
const CRITERIA_WEB = [
    {
        type: "Education",
        index: 0,
        content:
            "Completed full-stack web development course by passing assessments after each module in the program.",
    },
    {
        type: "Project",
        index: 1,
        content:
            "Completed multiple projects during the Web development course and final group project towards the end of the course.",
    },
    {
        type: "Assessment",
        index: 2,
        content:
            "Completed the Data Structures and Algorithms course and successfully passed the assessment.",
    },
    {
        type: "Assessment",
        index: 2,
        content:
            "Completed the Basics of DevOps course and passed the evaluation.",
    },
    {
        type: "Assessment",
        index: 2,
        content:
            "Passed final assessments and interviews demonstrating their technical and professional proficiencies.",
    },
    {
        type: "Portfolio",
        index: 3,
        content:
            "Complete building a responsive website using HTML, CSS, Bootstrap and also managing version control using Git and GitHub for Portfolio.",
    },
];

// data science
const BASE_INFO_DATA_SCIENCE = {
    title: "Data Science, Machine Learning & AI",
    issued_by: "TECH I.S. Programming School",
    detail: "This badge represents a TECH I.S. graduate's skill in Machine Learning. On earning this badge the student has a good understanding of various supervised and unsupervised learning algorithms. Students work on projects where they use supervised and unsupervised learning algorithms to identify and extract patterns from data. These projects are built using real-world data. Students work on a variety of problems like Regression, Classification, Clustering, NLP, and Image Recognition.",
};
const SKILL_DATA_SCIENCE = [
    "Analytics",
    "Apache Spark",
    "Data Processing and Visualisations",
    "Data Science",
    "Decision Trees/Ensemble Methods",
    "Deep Neural Networks",
    "Excel",
    "Exploratory Data Analysis",
    "Git",
    "GitHub",
    "Linear Algebra",
    "Linear Regression Models",
    "Machine Learning",
    "Model Deployment",
    "Model Optimisation",
    "MySQL",
    "Natural Language Processing",
    "Neural Networks",
    "Python",
    "Recommendation System",
    "SQL",
    "Statistics",
];
const CRITERIA_DATA_SCIENCE = [
    {
        type: "Education",
        index: 0,
        content:
            "Completed the Data Science, Machine Learning & AI course by passing assessments after each module in the program.",
    },
    {
        type: "Education",
        index: 0,
        content:
            "Cleared assessments for Python libraries like Pandas, Numpy, Matplotlib, seaborn, and SkLearn, Tensorflow, and Keras.",
    },
    {
        type: "Project",
        index: 1,
        content:
            "Completed projects on Supervised Learning algorithms like Linear and Logistic Regression, KNN, and Naive Bayes, Decision trees, and Random forest Algorithms, Bagged ensemble models, and boosting algorithms - ",
    },
    {
        type: "Project",
        index: 1,
        content:
            "Passed assessments and projects on the deployment of models using the python flask framework.",
    },
    {
        type: "Education",
        index: 0,
        content:
            "Completed projects and has cleared assessments on the use of clustering and Principal Component Analysis.",
    },
    {
        type: "Education",
        index: 0,
        content:
            "Cleared assessments for Python libraries like Pandas, Numpy, Matplotlib, seaborn, and SkLearn, Tensorflow, and Keras.",
    },
    {
        type: "Project",
        index: 1,
        content:
            "Completed projects and has cleared assessments on the use of clustering and Principal Component Analysis.",
    },
];

export const CRITERIA = [
    {
        main_course: "web",
        base_info: BASE_INFO_WEB,
        image: WEB_Icon,
        skill: SKILL_WEB,
        criteria: CRITERIA_WEB,
    },
    {
        main_course: "ds",
        base_info: BASE_INFO_DATA_SCIENCE,
        image: DS_Icon,
        skill: SKILL_DATA_SCIENCE,
        criteria: CRITERIA_DATA_SCIENCE,
    },
];
