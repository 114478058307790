import { configureStore } from "@reduxjs/toolkit";
import auth from "./auth";
import home from "./home";
import quiz from "./quiz";
import activity from "./activity";
import recommend_video from "./recommend";

const store = configureStore({
    reducer: {
        auth,
        home,
        quiz,
        activity,
        recommend_video,
    },
});

export default store;
