import Cookies from "js-cookie";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { TOKEN } from "src/constants";

export const AuthenticateRoute = ({ children }) => {
    const token = Cookies.get(TOKEN);

    useEffect(() => {
        if (token && window.location.pathname === "/login") {
            return <Navigate to="/" />;
        }

        // eslint-disable-next-line
    }, []);

    if (!token && window.location.pathname !== "/login") {
        const redirect_link = window.location.pathname + window.location.search;
        if (window.location.pathname) {
            return <Navigate to={"/login?redirect=" + redirect_link} />;
        } else {
            return <Navigate to="/login" />;
        }
    }

    return children;
};
