import React, { createContext, useContext, useEffect, useState } from "react";


const notifyBarContext = createContext(false)
export const useNotifyBarContext = () => useContext(notifyBarContext)

export default function NotifyBarLocal({ children }) {
    const [notify, setNotify] = useState(false)
    const [notifyStyle, setNotifyStyle] = useState(null)
    const [contents, setContents] = useState(null)
    const [type, setType] = useState(null)
    const notifyType = {
        success: 'Success',
        error: 'Error',
        warning: 'Warning',
        other: 'Other'
    }

    function showNotify(type, contents) {
        setNotifyStyle(type)
        setType(notifyType[type])
        setContents(null)
        if (contents) {
            //display some content about the status if needed
            setContents(contents)
        }
        setNotify(true)
    }

    useEffect(() => {
        const completeNotify = setTimeout(() => {
            setNotify(false)
        }, 3000);
        return () => {
            clearTimeout(completeNotify)
        }
    }, [notify])

    return (
        <notifyBarContext.Provider value={{ showNotify }}>
            {
                notify ?
                    <div className={` notify notify__${notifyStyle}`}>
                        <div >
                            <label htmlFor="">{type}</label>
                            {!!contents &&
                                <div className="notify__content">
                                    <label htmlFor="contents">
                                        {contents}
                                    </label>
                                </div>
                            }
                        </div>
                        <div>
                            <div className="notify-wrapper">
                                <div className="notify-arrow">
                                    <div className="notify-line"></div>
                                    <div className="notify-line"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
            }
            {children}
        </notifyBarContext.Provider>
    )
}