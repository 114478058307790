import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import arrowUp from "@assets/img/Vector-up.svg";
import arrowDown from "@assets/img/Vector-down.svg";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { SET_USER_ACTIVITY } from "@stores/activity";

export default function ContentItem({ item, handleGetLesson }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { lessonId } = useParams();
    const [isActive, setIsActive] = useState(false);
    function courseStatus() {
        const lesson = `${item.lessons_completed} / ${
            item.lessons.length + item.quizzes.length
        }`;
        const lessonDuration = getTotalDuration();
        return lessonDuration ? `${lesson} | ${lessonDuration}` : "";
    }

    useEffect(() => {
        setIsActive(item.lessons.find((lesson) => +lesson.id === +lessonId));
        if (item.lessons.find((lesson) => +lesson.id === +lessonId)) {
            const tempLesson = item.lessons.find(
                (lesson) => +lesson.id === +lessonId
            );
            dispatch(
                SET_USER_ACTIVITY({
                    section: {
                        section: tempLesson.section,
                        title: tempLesson.title,
                    },
                    lesson: tempLesson.id,
                })
            );
        }
        // eslint-disable-next-line
    }, [lessonId]);

    function getTotalDuration() {
        let temp = null;
        item?.lessons?.map(({ duration }) => {
            temp += duration;
            return temp;
        });
        item?.quizzes?.map(({ duration }) => {
            temp += duration;
            return temp;
        });
        if (temp > 60) return `${Math.trunc(temp / 60)} hr ${temp % 60} mins`;
        if (temp < 60 && temp) return temp + " mins";
    }

    function handleClickLesson(id) {
        handleGetLesson(id);
        navigate("/lesson/" + id);
    }

    return (
        <div>
            <div
                className="video-list__ttl disable-selected"
                onClick={() => setIsActive(!isActive)}
            >
                <div>
                    <h2>{item.name}</h2>
                    <label htmlFor="">{courseStatus()}</label>
                </div>
                <div>
                    <img
                        className="arrow"
                        src={isActive ? arrowDown : arrowUp}
                        alt=""
                    />
                </div>
            </div>
            <div
                className={`video-list__row_in ${isActive ? "is-active" : ""}`}
            >
                {item?.lessons?.map((el, index) => (
                    <li
                        key={index}
                        className={` ${
                            el.is_available
                                ? `video-list__item ${
                                      el.id.toString() === lessonId
                                          ? "is-playing"
                                          : ""
                                  }`
                                : "video-list__item not_available"
                        }`}
                        onClick={() => {
                            if (el.id.toString() !== lessonId)
                                handleClickLesson(el.id);
                        }}
                    >
                        <span className="ttl">{el.title}</span>
                        <span className="time">{el.duration}m</span>
                    </li>
                ))}
                {item?.quizzes?.map((el, index) => (
                    <li
                        key={index}
                        className={`${
                            el.is_available
                                ? `video-list__item ${
                                      el.result_type === "failed"
                                          ? "is-fail"
                                          : "is-quiz"
                                  }`
                                : "video-list__item not_available"
                        }`}
                        onClick={() => navigate("/quiz/" + el.id)}
                    >
                        <span className="ttl">{el.title}</span>
                        <span className="time">{el.duration}m</span>
                    </li>
                ))}
            </div>
        </div>
    );
}
