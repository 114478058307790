import BaseRequest from "./baseRequest";

class HomeRequest extends BaseRequest {
    fetchSection(courses_slug) {
        return this.performRequest(BaseRequest.METHOD_GET, `courses/${courses_slug}`)
    }
    fetchLessonDetails(id) {
        return this.performRequest(BaseRequest.METHOD_GET, `lessons/find/${id}`)
    }
}

export default new HomeRequest('')
