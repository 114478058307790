import React, { useEffect, useState } from "react";
import Badge from "../components/Badge";
import { CRITERIA } from "../constants/constants";

function iconBuilder(type) {
    const Experience = (
        <svg
            aria-labelledby="svg-inline--fa-title-48THTZCMZQbD"
            data-prefix="fal"
            data-icon="graduation-cap"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
        >
            <title id="svg-inline--fa-title-48THTZCMZQbD">
                Education Experience
            </title>
            <path
                fill="currentColor"
                d="M612.16 153.99l-265-85.68c-17.81-5.75-36.5-5.75-54.31 0l-265 85.68C10.94 159.46 0 174.38 0 192s10.94 32.54 27.84 38.01l29.71 9.6c-3.3 6.18-5.74 12.83-7.33 19.8C39.53 264.59 32 275.32 32 288c0 12.73 7.57 23.52 18.33 28.67L32.28 428.53C30.67 438.52 36.19 448 43.62 448h40.75c7.43 0 12.96-9.48 11.34-19.47L77.67 316.67C88.43 311.52 96 300.73 96 288c0-10.6-5.49-19.54-13.43-25.37 1.49-4.66 3.8-8.86 6.57-12.81l53.47 17.29L128 384c0 35.35 85.96 64 192 64s192-28.65 192-64l-14.61-116.89L612.16 230c16.9-5.46 27.84-20.38 27.84-38s-10.94-32.54-27.84-38.01zM479.48 381.86C468.72 393.19 414.04 416 320 416c-94.04 0-148.72-22.81-159.48-34.14l13.09-104.73 119.24 38.55c2.6.84 25.72 9.23 54.31 0l119.24-38.55 13.08 104.73zm122.8-182.28l-265 85.68c-11.31 3.66-23.25 3.66-34.56 0l-175.67-56.8 195.89-36.74c8.69-1.62 14.41-9.98 12.78-18.67-1.62-8.7-10.16-14.39-18.66-12.77l-203.78 38.2c-12.4 2.32-23.51 7.65-33.08 14.83l-42.49-13.74c-7.85-2.55-7.46-12.74 0-15.15l265-85.68c15.1-4.88 27.84-2.17 34.56 0l265 85.68c7.39 2.39 7.91 12.6.01 15.16z"
            ></path>
        </svg>
    );
    const Project = (
        <svg
            aria-labelledby="svg-inline--fa-title-Aq9xHu0hq943"
            data-prefix="fal"
            data-icon="project-diagram"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
        >
            <title id="svg-inline--fa-title-Aq9xHu0hq943">Project</title>
            <path
                fill="currentColor"
                d="M592 0h-96c-26.51 0-48 21.49-48 48v32H192V48c0-26.51-21.49-48-48-48H48C21.49 0 0 21.49 0 48v96c0 26.51 21.49 48 48 48h94.86l88.76 150.21c-4.77 7.46-7.63 16.27-7.63 25.79v96c0 26.51 21.49 48 48 48h96c26.51 0 48-21.49 48-48v-96c0-26.51-21.49-48-48-48h-96c-5.2 0-10.11 1.04-14.8 2.57l-83.43-141.18C184.8 172.59 192 159.2 192 144v-32h256v32c0 26.51 21.49 48 48 48h96c26.51 0 48-21.49 48-48V48c0-26.51-21.49-48-48-48zM32 144V48c0-8.82 7.18-16 16-16h96c8.82 0 16 7.18 16 16v96c0 8.82-7.18 16-16 16H48c-8.82 0-16-7.18-16-16zm336 208c8.82 0 16 7.18 16 16v96c0 8.82-7.18 16-16 16h-96c-8.82 0-16-7.18-16-16v-96c0-8.82 7.18-16 16-16h96zm240-208c0 8.82-7.18 16-16 16h-96c-8.82 0-16-7.18-16-16V48c0-8.82 7.18-16 16-16h96c8.82 0 16 7.18 16 16v96z"
            ></path>
        </svg>
    );
    const Assessment = (
        <svg
            aria-labelledby="svg-inline--fa-title-BaCFP2zSCVLS"
            data-prefix="fal"
            data-icon="clipboard-check"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
        >
            <title id="svg-inline--fa-title-BaCFP2zSCVLS">Assessment</title>
            <path
                fill="currentColor"
                d="M336 64h-88.6c.4-2.6.6-5.3.6-8 0-30.9-25.1-56-56-56s-56 25.1-56 56c0 2.7.2 5.4.6 8H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM192 32c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm160 432c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16h48v20c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12V96h48c8.8 0 16 7.2 16 16v352zm-58.9-236.4c-4.7-4.7-12.3-4.7-17-.1L158.4 344.3 108 293.7c-4.7-4.7-12.3-4.7-17 0l-8.5 8.5c-4.7 4.7-4.7 12.3 0 17l67.4 67.6c4.7 4.7 12.3 4.7 17 0l134.8-133.7c4.7-4.7 4.7-12.3.1-17l-8.7-8.5z"
            ></path>
        </svg>
    );
    const Portfolio = (
        <svg
            aria-labelledby="svg-inline--fa-title-llydNMwUWTm2"
            data-prefix="fal"
            data-icon="palette"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
        >
            <title id="svg-inline--fa-title-llydNMwUWTm2">Portfolio</title>
            <path
                fill="currentColor"
                d="M112 264c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm32-112c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zM256 0c-16.9 0-34.2 1.6-51.7 5C104.9 24.4 24.8 104.3 5.2 203.4-29.4 378.5 116.4 512 239.5 512c8.3 0 16.5-.6 24.6-1.9 41.2-6.4 61.4-54.6 42.5-91.7-23.1-45.4 9.9-98.4 60.9-98.4h79.7c35.8 0 64.8-29.6 64.9-65.3C511.6 113.9 397.1 0 256 0zm191.1 288h-79.7c-35.3 0-67.4 17.9-85.7 47.8-18.2 29.7-19.6 66-3.7 97.2 4.9 9.6 4.8 21.6-.1 31.3-2.4 4.6-7.9 12.6-18.7 14.3-6.3 1-12.9 1.5-19.7 1.5-54.6 0-114.1-31.3-155.5-81.6-44-53.6-60.9-120.6-47.4-188.7 17.1-86.6 87-156.2 173.9-173.2 15.2-3 30.5-4.5 45.5-4.5 123.1 0 223.6 99.9 224 222.6 0 18.3-14.8 33.3-32.9 33.3zM368 136c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zM240 88c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24z"
            ></path>
        </svg>
    );
    const item = [Experience, Project, Assessment, Portfolio];
    return item[type];
}
function BaseInfo(props) {
    const { info } = props;
    const title = info?.base_info?.title;
    const detail = info?.base_info?.detail;
    const issued_by = info?.base_info?.issued_by;
    return (
        <div className="cf-body__base-info">
            <h1>{title}</h1>
            <div className="cf-body__base-info-issuer">
                Issued by &#160;
                <u>{issued_by}</u>
            </div>
            <div className="cf-body__base-info-detail">{detail}</div>
            <div className="cf-badge__list cf-body__base-info-badge-list">
                <div>
                    <Badge
                        icon={
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="far"
                                data-icon="file-signature"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512"
                            >
                                <path
                                    fill="currentColor"
                                    d="M48.02 448L48 64.13c0-8.836 7.164-16 16-16h160L224 128c0 17.67 14.33 32 32 32h79.1v94.18L384 206.5V138.6c0-16.98-6.742-33.26-18.74-45.26l-74.63-74.64C278.6 6.742 262.3 0 245.4 0H63.93C28.58 0 0 28.65 0 64l.0065 384c0 35.34 28.58 64 63.93 64H320c29.65 0 54.53-20.52 61.71-48H64.02C55.18 464 48.02 456.8 48.02 448zM292.7 342.3C289.7 345.3 288 349.4 288 353.7V383.9L272.8 384c-4.125 0-8.125-2.5-10.12-6.5c-11.88-23.88-46.25-30.38-66-14.12l-13.88-41.63C179.5 311.9 170.4 305.3 160 305.3s-19.5 6.625-22.75 16.5L119 376.4C117.5 380.9 113.3 384 108.4 384H96c-8.875 0-16 7.125-16 16S87.13 416 96 416h12.38c18.62 0 35.13-11.88 41-29.5L160 354.6L176.9 405c2 6.25 7.5 10.5 14 11H192c5.999 0 11.62-3.375 14.25-8.875l7.749-15.38C216.8 386.3 221.9 385.6 224 385.6s7.25 .625 10.12 6.5C241.5 406.9 256.4 416 272.8 416h77.59c4.264 0 8.35-1.703 11.35-4.727l156.9-158l-67.88-67.88L292.7 342.3zM568.5 167.4L536.6 135.5c-9.875-10-26-10-36 0l-27.25 27.25l67.88 67.88l27.25-27.25C578.5 193.4 578.5 177.3 568.5 167.4z"
                                ></path>
                            </svg>
                        }
                        isInfo
                        name="Certification"
                    />
                </div>
                <div>
                    <Badge
                        icon={
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="far"
                                data-icon="layer-group"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                            >
                                <path
                                    fill="currentColor"
                                    d="M231.2 5.092C239 1.732 247.5 0 256 0C264.5 0 272.1 1.732 280.8 5.092L490.1 94.79C503.4 100.5 512 113.5 512 128C512 142.5 503.4 155.5 490.1 161.2L280.8 250.9C272.1 254.3 264.5 256 256 256C247.5 256 239 254.3 231.2 250.9L21.9 161.2C8.614 155.5 0 142.5 0 128C0 113.5 8.614 100.5 21.9 94.79L231.2 5.092zM256 48C253.1 48 251.1 48.41 250.1 49.21L66.26 128L250.1 206.8C251.1 207.6 253.1 208 256 208C258 208 260 207.6 261.9 206.8L445.7 128L261.9 49.21C260 48.41 258 48 256 48V48zM250.1 334.8C251.1 335.6 253.1 336 256 336C258 336 260 335.6 261.9 334.8L452 253.3C447.4 246.4 446.5 237.2 450.5 229.3C456.5 217.4 470.9 212.6 482.7 218.5L491.8 223.1C504.2 229.3 512 241.9 512 255.7C512 270.4 503.3 283.6 489.9 289.3L280.8 378.9C272.1 382.3 264.5 384 256 384C247.5 384 239 382.3 231.2 378.9L22.81 289.6C8.971 283.7 .0006 270.1 .0006 255C.0006 242.9 5.869 231.5 15.76 224.4L26.05 217C36.84 209.3 51.83 211.8 59.53 222.6C66.15 231.9 65.24 244.3 57.1 252.5L250.1 334.8zM59.53 350.6C66.15 359.9 65.24 372.3 57.1 380.5L250.1 462.8C251.1 463.6 253.1 464 256 464C258 464 260 463.6 261.9 462.8L452 381.3C447.4 374.4 446.5 365.2 450.5 357.3C456.5 345.4 470.9 340.6 482.7 346.5L491.8 351.1C504.2 357.3 512 369.9 512 383.7C512 398.4 503.3 411.6 489.9 417.3L280.8 506.9C272.1 510.3 264.5 512 256 512C247.5 512 239 510.3 231.2 506.9L22.81 417.6C8.971 411.7 .001 398.1 .001 383C.001 370.9 5.87 359.5 15.76 352.4L26.05 345C36.84 337.3 51.83 339.8 59.53 350.6L59.53 350.6z"
                                ></path>
                            </svg>
                        }
                        isInfo
                        name="Intermediate"
                    />
                </div>
                <div>
                    <Badge
                        icon={
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="far"
                                data-icon="clock"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                            >
                                <path
                                    fill="currentColor"
                                    d="M232 120C232 106.7 242.7 96 256 96C269.3 96 280 106.7 280 120V243.2L365.3 300C376.3 307.4 379.3 322.3 371.1 333.3C364.6 344.3 349.7 347.3 338.7 339.1L242.7 275.1C236 271.5 232 264 232 255.1L232 120zM256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0zM48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48C141.1 48 48 141.1 48 256z"
                                ></path>
                            </svg>
                        }
                        isInfo
                        name="Months"
                    />
                </div>
                <div>
                    <Badge
                        icon={
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="far"
                                data-icon="dollar-sign"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                            >
                                <path
                                    fill="currentColor"
                                    d="M184 73.46C211.3 76.26 236.8 81.56 255.5 85.98C268.4 89.03 276.4 101.1 273.4 114.9C270.3 127.8 257.4 135.7 244.5 132.7C213.7 125.4 168.9 117 130.6 120.2C111.6 121.7 96.18 125.1 85.27 132.9C75.19 139.3 67.98 148.5 65.09 163.3C62.82 175 64.4 182.5 67.05 187.9C69.87 193.5 75.14 199.1 84.19 204.7C103.4 216.6 131.1 224.1 165.9 232.7L168.2 233.3C199.2 241.2 234.9 250.3 261.1 266.5C275 275.1 287.9 286.7 295.9 302.7C304 318.1 305.1 337.5 302 357.8C295.1 393.2 269.4 416.3 237.5 428.4C221.4 434.6 203.3 438.2 184 439.4V488C184 501.3 173.3 512 160 512C146.7 512 136 501.3 136 488V438.3C132.7 437.9 129.4 437.5 126.1 437.1L125.1 437C101.1 433.4 57.02 423.2 30.25 411.3C18.14 405.9 12.69 391.7 18.07 379.6C23.45 367.5 37.64 362 49.75 367.4C70.96 376.8 110.2 386.2 132.8 389.5C168.4 394.5 198.8 391.8 220.4 383.6C241.5 375.5 252.1 363.3 254.9 348.7C257.2 336.1 255.6 329.5 252.9 324.1C250.1 318.5 244.9 312.9 235.8 307.3C216.6 295.4 188 287.9 154.1 279.3L151.8 278.7C120.8 270.8 85.09 261.7 58.92 245.5C44.98 236.9 32.13 225.3 24.12 209.3C15.96 193 14.03 174.5 17.98 154.2C23.46 125.1 38.71 105.6 59.54 92.39C79.54 79.68 103.5 74.2 126.7 72.32C129.8 72.07 132.9 71.88 136 71.74V24C136 10.75 146.7 0 160 0C173.3 0 184 10.75 184 24L184 73.46z"
                                ></path>
                            </svg>
                        }
                        isInfo
                        name="Paid"
                    />
                </div>
            </div>
        </div>
    );
}
function SkillInfo(props) {
    const { info } = props;
    return (
        <div className="cf-body__skill">
            <div>
                <h1 className="cf-body__skill-ttl">Skills</h1>
            </div>
            <div>
                <>
                    <ul>
                        {info?.skill?.map((index) => (
                            <li key={index}>
                                <div>{index}</div>
                            </li>
                        ))}
                    </ul>
                </>
            </div>
        </div>
    );
}
function EarningCriteria(props) {
    const { info } = props;
    return (
        <div className="cf-body__criteria">
            <h1 className="cf-body__criteria-ttl">Earning Criteria</h1>
            <ul>
                {info?.criteria?.map((el, index) => (
                    <li key={index}>
                        <div className="cf-body__icon">
                            {iconBuilder(el.index)}
                        </div>
                        <div className="cf-body__content">{el.content}</div>
                    </li>
                ))}
            </ul>
        </div>
    );
}
export default function Body(props) {
    const { data } = props;
    const [mainCourse, setMainCourse] = useState("");

    useEffect(() => {
        CRITERIA.map((el) => {
            if (data?.main_course?.slug === el.main_course) {
                setMainCourse(el);
                return el;
            }
            return el;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <div className="cf-body__container">
            <div className="cf-body__container-img">
                <img src={mainCourse.image} alt="Full-stack Development" />
            </div>
            <div className="cf-body__container-detail">
                <BaseInfo info={mainCourse} />
                <SkillInfo info={mainCourse} />
                <EarningCriteria info={mainCourse} />
            </div>
        </div>
    );
}
