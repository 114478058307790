import Badge from "@assets/img/badge.png";
import { useEffect, useState } from "react";
import infoIcon from "@assets/img/info-512.png";

export default function QuizItem({
    question_id,
    data,
    handleSetForm,
    is_submitted,
}) {
    const [currentCheck, setCurrentCheck] = useState();

    useEffect(() => {
        setCurrentCheck(data?.selected_answer?.answer);
    }, [data?.selected_answer?.answer]);

    function Item({ id, answer, is_correct }) {
        return (
            <>
                <input
                    type="radio"
                    name={data.id}
                    value={id}
                    onChange={(e) => handleChange(e, id)}
                    disabled={
                        is_submitted
                            ? +currentCheck === +id && !is_correct
                            : null
                    }
                    checked={is_submitted ? is_correct : +currentCheck === +id}
                />
                <label className="quiz__wrap__space" htmlFor="checkbox">
                    {" "}
                    {answer}{" "}
                </label>
            </>
        );
    }

    function handleChange(event) {
        const currentValue = event.target.value;
        if (!is_submitted) {
            setCurrentCheck(currentValue);
        }
        handleSetForm(event);
    }

    return (
        <div
            className={
                !data.selected_answer && is_submitted
                    ? "quiz__wrap "
                    : "quiz__wrap"
            }
        >
            <div className="quiz__header">
                <div>
                    <h1 className="quiz__wrap__question">
                        Q-{question_id}) {data.question}
                    </h1>
                </div>
                <div className="badge">
                    <img src={Badge} alt="badge" />
                    <label htmlFor="score" className="badge-score">
                        {data.score}
                    </label>
                    <div className="badge-text">POINT</div>
                </div>
            </div>
            {!!data?.image && (
                <>
                    <div className="image-height">
                        <img src={data?.image} alt="" />
                    </div>
                </>
            )}

            {data.answers.map((el, index) => (
                <div key={index}>
                    <div className="quiz__content">
                        <Item
                            id={el.id}
                            answer={el.answer}
                            is_correct={el.is_correct}
                        />
                    </div>
                    {!!el?.image && (
                        <>
                            <div className="image-height">
                                <img src={el.image} alt="" />
                            </div>
                        </>
                    )}
                </div>
            ))}
            <div>
                {!!data?.explanation && is_submitted && (
                    <div className="quiz__explanation">
                        <div>
                            <label htmlFor="explanation">Explanation</label>
                            <div className="quiz__explanation-sub">
                                {data?.explanation}
                            </div>
                        </div>
                    </div>
                )}
                {!data?.selected_answer && is_submitted && (
                    <div className="quiz__explanation">
                        <label htmlFor="explanation">FYI</label>
                        <div className="quiz__explanation-sub-info">
                            <div className="main">
                                You did not select any answers.
                            </div>
                            <div>
                                <img
                                    src={infoIcon}
                                    alt="Info icon"
                                    width={24}
                                    height={24}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
