import QuizRequest from "src/requests/quizRequest";
import { SET_QUIZZES } from ".";

export function fetchQuizDetails(id) {
    return async (dispatch) => {
        const response = await QuizRequest.fetchQuizDetails(id)
        dispatch(SET_QUIZZES(response.data))
    }
}

export function startQuiz(id) {
    return async (dispatch) => {
        const response = await QuizRequest.startQuiz(id)
        return response
        //if response show alert message and navigate to quiz page
    }
}
export function sendQuiz(id, payload) {
    return async (dispatch, state) => {
        const response = await QuizRequest.sendQuiz(id, payload)
        return response
    }
}

export function submitQuiz(id) {
    return async (dispatch) => {
        const response = await QuizRequest.submitQuiz(id)
        return response
    }
}
