import YouTube from 'react-youtube';

export default function videoEmbedded({videoId, customStyle}) {

    const opts = {
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: false,
            // please change below origin according to server origin
            origin: 'http://localhost:3001' 
        },
    };

    function onReady(event) {
        // access to player in all event handlers via event.target
        return event.target.pauseVideo();
    }

    return (
        <div className={customStyle ? customStyle:'yt-video'}>
            <YouTube videoId={videoId} opts={opts} onReady={onReady}>
            </YouTube>
        </div>
    )
}