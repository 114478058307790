import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatDate } from '@utils/common'
import avatar from '@assets/img/sample-avatar-l.png'
import { updateUserProfile } from '@stores/auth/thunk'
import { useNotifyBarContext } from '@contexts/notifyBarContext'

export default function Index({ isUploadable }) {
    const { user } = useSelector((state) => state.auth)
    const { showNotify } = useNotifyBarContext()
    const dispatch = useDispatch()

    //local state
    const [uploadStyle, setUploadStyle] = useState(false)
    const [loading, setLoading] = useState(false)
    const uploadProfileRef = useRef()

    function handleUploadImage() {
        uploadProfileRef.current.value = null
        uploadProfileRef.current.click()
    }

    function handleUploadingStyle() {
        if (uploadStyle) {
            return 'btn__image'
        } else if (loading) {
            return 'on-top__image'
        }
    }

    async function uploadToCloud(e) {
        const profile = e.target.files[0]
        try {
            setUploadStyle(false)
            setLoading(true)
            await dispatch(updateUserProfile({ profile }))
            showNotify('success', null)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            showNotify('error', 'Network Error')
        }
    }
    
    return (
        <div className="profile">
            <div className="profile__avatar" onMouseEnter={() => setUploadStyle(true)} onMouseLeave={() => setUploadStyle(false)}>
                <div>
                    <img src={user?.profile ?? avatar} className={`${handleUploadingStyle()}`} alt="" decoding="async" />
                    {
                        !!isUploadable &&
                        <>
                            <input ref={uploadProfileRef} type="file" accept='image/*' id='profile_image' hidden onChange={uploadToCloud} />
                            {uploadStyle ? <button className='profile__avatar btn' onClick={handleUploadImage} disabled={loading}>Upload</button> :
                                <div className={loading ? "lds-ring on-top" : ""}><div></div><div></div><div></div><div></div>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>


            <p className="profile__name">{user.name}</p>
            <p className="profile__account">TECHIS{user.crm_id}</p>
            {/* <div className="profile__data">
                <p className="profile__data-subttl">10 Days Ahead</p>
                <div className="profile__data-prog">
                    <progress id="file" max="100" value="35"></progress>
                    <span>35%</span>
                </div>
                <p className="profile__data-desc">Course Progress</p>
            </div> */}
            <div className="profile__data">
                <p className="profile__data-ttl">{formatDate(user.start_date)}</p>
                <p className="profile__data-desc">Course Start Date</p>
            </div>
            <div className="profile__data">
                <p className="profile__data-ttl">{user.attendances_info}</p>
                <p className="profile__data-desc">Attendance</p>
            </div>
            <div className="profile__data">
                <p className="profile__data-ttl">{formatDate(user.graduation_date)}</p>
                <p className="profile__data-desc">Expected Completion Date</p>
            </div>
        </div>
    )
}
