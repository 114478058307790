import { Markdown } from "@components/";
import { useNotifyBarContext } from "@contexts/notifyBarContext";
import { useProgressBarContext } from "@contexts/progressBarContext";
import { fetchLessonDetails, fetchSection } from "@stores/home/thunk";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import ContentItem from "./ContentItem";
import closeIcon from "@assets/img/close.svg";
import courseBack from "@assets/img/courseback.svg";
import { getCurrentCourseState, isMobileDevice } from "@utils/common";
import { VideoEmbedded } from "@components/index";
import { useTransition } from "react";

export default function Content() {
    const { lessonId } = useParams();
    const { lessonDetails, section } = useSelector((state) => state?.home);
    const [error, setError] = useState(null);
    // const { user } = useSelector((state) => state.auth);
    const [toggleAside, setToggleAside] = useState(true);
    const { showNotify } = useNotifyBarContext();
    const dispatch = useDispatch();
    const location = useLocation();
    const refVideoContents = useRef(null);
    const [videoID, setVideoID] = useState(null);
    const { showProgress, completeProgress } = useProgressBarContext();
    const [content, setContent] = useState(null);
    const [isPending, startTransition] = useTransition();

    async function updateSize() {
        setToggleAside(window.innerWidth >= 980);
    }

    useEffect(() => {
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        getSection();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isMobileDevice()) {
            setToggleAside(!isMobileDevice());
        }
    }, [location]);

    useEffect(() => {
        if (lessonDetails?.lesson?.main_video_url) {
            const url = new URL(
                lessonDetails?.lesson?.main_video_url.toString()
            );
            const paramsURL = new URLSearchParams(url.search.toString());
            setVideoID(paramsURL.get("v"));
        } else {
            setVideoID(null);
        }
        updateSize();
        //eslint-disable-next-line
    }, [lessonDetails]);

    useEffect(() => {
        if (Object.keys(lessonDetails).length === 0) {
            showProgress();
        } else if (Object.keys(lessonDetails).length !== 0) {
            completeProgress();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lessonDetails]);

    function scrollToTopAuto() {
        refVideoContents.current.scroll({ top: 0, behavior: "smooth" });
    }

    async function getSection() {
        try {
            const course = getCurrentCourseState();
            dispatch(fetchSection(course.slug));
            getLesson(lessonId);
        } catch {
            window.location.href = '/'
            // dispatch(fetchSection(user?.courses?.[0]?.slug));
            // getLesson(section.results[0].lessons.id);
        }
    }

    async function getLesson(lessonId) {
        setContent(null);
        try {
            const result = await dispatch(fetchLessonDetails(lessonId));
            const data = result.data.lesson.content;
            startTransition(() => {
                setContent(data);
            });
            scrollToTopAuto();
            setError(null);
            return result;
        } catch (e) {
            setError(e.message);
            showNotify("error", "Not Found");
        }
    }

    return (
        <>
            <div className="video">
                <div className="contents-video">
                    <div className="video-main">
                        <main className="video-contents" ref={refVideoContents}>
                            {!!videoID && <VideoEmbedded videoId={videoID} />}
                            <div className="sub-content">
                                <h1 className="video-title">
                                    {error ? "" : lessonDetails?.lesson?.title}
                                </h1>
                                <hr />
                                {error ? (
                                    error
                                ) : isPending ? (
                                    <div className="loading-markdown">
                                        <div className="lds-ring">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                ) : (
                                    <Markdown content={content}></Markdown>
                                )}
                                {!toggleAside && (
                                    <div
                                        className="course-content"
                                        onClick={() => setToggleAside(true)}
                                    >
                                        <img src={courseBack} alt="back" />
                                    </div>
                                )}
                            </div>
                        </main>
                        <>
                            {!!toggleAside && (
                                <aside className="video-list">
                                    <div className="video-list__ttl-main">
                                        {/* change course name here */}
                                        <h2 src="">
                                            {getCurrentCourseState()?.name ??
                                                "..."}
                                        </h2>
                                        <img
                                            src={closeIcon}
                                            alt="close"
                                            onClick={() =>
                                                setToggleAside(false)
                                            }
                                        />
                                    </div>
                                    <ul className="video-list__items">
                                        {section?.results?.map((el, index) => {
                                            return (
                                                <ContentItem
                                                    key={el.id + index}
                                                    item={el}
                                                    handleGetLesson={getLesson}
                                                />
                                            );
                                        })}
                                    </ul>
                                </aside>
                            )}
                        </>
                    </div>
                </div>
            </div>
        </>
    );
}
