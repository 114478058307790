import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

export default function CategoryVideoList(props) {
    const navigate = useNavigate();
    const { category } = useParams();
    const { categoryVideoList } = props;
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();

    function handleNavigate(currentId) {
        navigate(`/recommend/${currentId.id}?recommend=` + searchParams.get("recommend"));
    }

    return (
        <div>
            <ul>
                    {categoryVideoList?.results?.map((item, index) => {
                    let tempValue = item.video ?? item;
                    return (
                        <li
                            className="recommend-detail__main-aside__list"
                            key={index}
                        >
                            <div
                                className={
                                    "recommend-detail__main-aside__list-item " +
                                    `${
                                        +tempValue.id === +category
                                            ? "is-playing"
                                            : ''
                                    }`
                                }
                                onClick={() => {
                                    if (+tempValue.id !== +category) {
                                        handleNavigate(tempValue);
                                    }
                                }}
                            >
                                <div className="recommend-detail__main-aside__list-item-img">
                                    <img
                                        src={
                                            tempValue.thumbnail ??
                                            tempValue.youtube_thumbnail
                                        }
                                        draggable={false}
                                        alt=""
                                    />
                                </div>
                                <div className="recommend-detail__main-aside__list-item-ttl">
                                    <label htmlFor="">{tempValue.title}</label>
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
