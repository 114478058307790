export default function Message() {

    return (
        <div className="message">
            <div className="contents">
                <div className="main">
                    <div className="main-header">
                        <h2 className="main-header__ttl">Messages</h2>
                    </div>
                </div>

                <div className="message-wrap">
                    <main className="message-contents">
                        <div className="message-head">
                            <div className="message-head__thumb"><img src="/assets/img/message-icon-techis.svg" alt="" decoding="async"/></div>
                            <div className="message-head__meta">
                                <h3 className="message-head__name">Tech I.S.</h3>
                                <span className="message-head__status is-online">Online</span>
                            </div>
                        </div>
                        <div className="message-body">
                            <div className="message-body__main">
                                <div className="message-body__from">
                                    <p>Thanks so much for helping me with the course of Python Rajeshwari</p>
                                    <span>Now</span>
                                </div>
                                <div className="message-body__re">
                                    <p>Hey Julian</p>
                                </div>
                                <div className="message-body__re">
                                    <p>You’re very welcome. If you’d like to register your interest complete the form below. Thanks again
                                        let me know if you are interested to do Web Development course</p>
                                    <span>09:37</span>
                                </div>
                                <h3 className="message-body__time">TODAY 15.09</h3>
                                <div className="message-body__from">
                                    <p>Was great to see you morning we will have  a session soon </p>
                                    <span>09:32</span>
                                </div>
                                <div className="message-body__re">
                                    <p>Was great to see you morning we will have  a session soon </p>
                                </div>
                                <div className="message-body__re">
                                    <p>could you please share me the assignments of the last month which we worked together.</p>
                                    <span>09:37</span>
                                </div>
                            </div>
                            <form className="message-body__footer">
                                <input type="image" className="message-body__file" alt="imag" src="/assets/img/message-icon-file.svg" />
                                <input type="text" className="message-body__text" alt="imag" placeholder="Type Message..." />
                                <input type="image" className="message-body__submit" alt="imag" src="/assets/img/message-icon-submit.svg" />
                            </form>
                        </div>
                    </main>
                    <aside className="message-sidebar">
                        <section className="message-photo">
                            <h3 className="message-sidebar__ttl">PHOTOS FILE</h3>
                            <ul className="message-photo__list">
                                <li className="message-photo__item"><img src="/assets/img/sample-image2.png" alt="" decoding="async" /></li>
                                <li className="message-photo__item"><img src="/assets/img/sample-image2.png" alt="" decoding="async" /></li>
                                <li className="message-photo__item"><img src="/assets/img/sample-image2.png" alt="" decoding="async" /></li>
                                <li className="message-photo__item"><img src="/assets/img/sample-image2.png" alt="" decoding="async" /></li>
                                <li className="message-photo__item"><img src="/assets/img/sample-image2.png" alt="" decoding="async" /></li>
                                <li className="message-photo__item"><div className="message-photo__cover">35+</div><img src="/assets/img/sample-image2.png" alt="" decoding="async" /></li>
                            </ul>
                        </section>
                        <section className="message-files">
                            <h3 className="message-sidebar__ttl">ANOTHERS FILE</h3>
                            <ul className="message-files__list">
                                <li className="message-files__item">
                                    <div className="message-files__thumb"><img src="/assets/img/message-icon-doc-blue.svg" alt="" decoding="async" /></div>
                                    <div className="message-files__meta">
                                        <p className="message-files__name">Python.zip</p>
                                        <p className="message-files__size">16 Mb</p>
                                    </div>
                                    <div className="message-files__icon"><img src="/assets/img/message-icon-dl.svg" alt="" decoding="async" /></div>
                                </li>
                                <li className="message-files__item">
                                    <div className="message-files__thumb"><img src="/assets/img/message-icon-doc-green.svg" alt="" decoding="async" /></div>
                                    <div className="message-files__meta">
                                        <p className="message-files__name">React.zip</p>
                                        <p className="message-files__size">16 Mb</p>
                                    </div>
                                    <div className="message-files__icon"><img src="/assets/img/message-icon-dl.svg" alt="" decoding="async" /></div>
                                </li>
                                <li className="message-files__item">
                                    <div className="message-files__thumb"><img src="/assets/img/message-icon-doc-orange.svg" alt="" decoding="async" /></div>
                                    <div className="message-files__meta">
                                        <p className="message-files__name">document2.zip</p>
                                        <p className="message-files__size">16 Mb</p>
                                    </div>
                                    <div className="message-files__icon"><img src="/assets/img/message-icon-dl.svg" alt="" decoding="async" /></div>
                                </li>
                                <li className="message-files__item">
                                    <div className="message-files__thumb"><img src="/assets/img/message-icon-doc-purple.svg" alt="" decoding="async" /></div>
                                    <div className="message-files__meta">
                                        <p className="message-files__name">document1.zip</p>
                                        <p className="message-files__size">16 Mb</p>
                                    </div>
                                    <div className="message-files__icon"><img src="/assets/img/message-icon-dl.svg" alt="" decoding="async" /></div>
                                </li>
                            </ul>
                        </section>
                    </aside>
                </div>
            </div>
        </div>
    )
}