import { getRecommendedVideos } from "@stores/recommend/thunk";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RecommendSection from "./RecommendSection";

export default function RecommendVideo() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { recommend_videos, categories } = useSelector(
        (state) => state.recommend_video
    );

    useEffect(() => {
        fetchRecommendVideoList();
        // eslint-disable-next-line
    }, []);

    async function fetchRecommendVideoList() {
        setIsLoading(true);
        try {
            await dispatch(getRecommendedVideos());
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="recommend-main">
                {isLoading ? (
                    <div className="loading-wrapper">
                        <div className="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                ) : (
                    // {/* {!isLoading && ( */}
                    <>
                        {recommend_videos?.results?.length > 0 && (
                            <div className="recommend-wrapper">
                                <h1 className="recommend-wrapper__title">
                                    Recommended Videos
                                </h1>
                                <br />
                                <RecommendSection
                                    data={recommend_videos}
                                    is_recommend={true}
                                />
                            </div>
                        )}
                        <div className="recommend-wrapper">
                            {categories?.results?.map((item, index) => {
                                return (
                                    <div key={index}>
                                        {item?.videos.length > 0 && (
                                            <>
                                                <h1 className="recommend-wrapper__title">
                                                    {item?.name}
                                                </h1>
                                                <br />
                                                <RecommendSection
                                                    categoryId={item.id}
                                                    data={{
                                                        results: item?.videos,
                                                    }}
                                                    is_recommend={false}
                                                />
                                                <br />
                                            </>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
