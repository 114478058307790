import { createSlice } from "@reduxjs/toolkit";
import { TECHIS_USER_ACTIVITY } from "src/constants";

const activity = createSlice({
    name: "activity",
    initialState: {
        tab_status: null,
        page: null,
        course: {},
        section: {},
        lesson: null,
    },
    reducers: {
        SET_USER_ACTIVITY(state, { payload }) {
            const oldValue = JSON.parse(
                localStorage.getItem(TECHIS_USER_ACTIVITY)
            );
            const value = {
                course: payload?.course ?? oldValue?.course,
                section: payload?.section ?? oldValue?.section,
                lesson: payload?.lesson ?? oldValue?.lesson
            };
            localStorage.setItem(TECHIS_USER_ACTIVITY, JSON.stringify(value));

            return {
                ...state,
                ...payload,
            };
        },
    },
});

export const { SET_USER_ACTIVITY } = activity.actions;
export default activity.reducer;
