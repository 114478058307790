import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { login } from "@stores/auth/thunk";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { TOKEN } from "src/constants";

export default function Login() {
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [form, setForm] = useState({
        login_name: "",
        password: "",
        remember_me: false,
    });
    // eslint-disable-next-line 
    const [redirectURL, setRedirectURL] = useSearchParams();

    function onEyeClick() {
        setIsShowPassword(!isShowPassword);
    }

    useEffect(() => {
        const token = Cookies.get(TOKEN);
        if (token && window.location.pathname === "/login") {
            navigation("/", { replace: true });
        }
        // eslint-disable-next-line
    }, []);

    async function onSubmit(evt) {
        evt.preventDefault();
        const redirect_link = redirectURL.get("redirect");
        try {
            setLoading(true);
            await dispatch(login(form));
            if (redirect_link) {
                navigation(redirect_link, { replace: true });
            } else {
                navigation("/", { replace: true });
            }
        } catch (error) {
            setLoading(false);
            setError(error.response.data);
        }
    }

    return (
        <div className="login">
            <div className="login-container">
                <div className="login-catch">
                    <h1 className="login-catch__ttl">
                        <img
                            src={
                                require("@assets/img/login-techis.svg").default
                            }
                            alt=""
                            decoding="async"
                        />
                    </h1>
                    <div className="login-catch__thumb">
                        <img
                            src={require("@assets/img/login-catch.png")}
                            alt=""
                            decoding="async"
                        />
                    </div>
                    <p className="login-catch__text">
                        Little Things Make Big Things
                        <br />- Code Everyday!
                    </p>
                </div>
                <form
                    onSubmit={onSubmit}
                    className="login-form"
                    autoComplete="off"
                >
                    <h2 className="login-form__ttl">WELCOME</h2>
                    <label htmlFor="email">Name / Email</label>
                    <div className="login-form__input">
                        <input
                            type="text"
                            name="email"
                            id="email"
                            placeholder="techis@example.com"
                            onChange={(e) =>
                                setForm((prevState) => ({
                                    ...prevState,
                                    login_name: e.target.value,
                                }))
                            }
                        />
                    </div>
                    {/* error user name */}
                    <div className="text-danger text-danger__spacing">
                        <label htmlFor="error">{error?.login_name?.[0]}</label>
                    </div>
                    <label htmlFor="password">Password</label>
                    <div className="login-form__input">
                        <input
                            type={isShowPassword ? "text" : "password"}
                            name="password"
                            id="password"
                            placeholder="●●●●●●●●"
                            onChange={(e) =>
                                setForm((prevState) => ({
                                    ...prevState,
                                    password: e.target.value,
                                }))
                            }
                        ></input>

                        <span className="eye" onClick={onEyeClick}>
                            {isShowPassword ? (
                                <img
                                    src={
                                        require("@assets/img/login-eye.svg")
                                            .default
                                    }
                                    alt=""
                                />
                            ) : (
                                <img
                                    src={
                                        require("@assets/img/login-not-eye.svg")
                                            .default
                                    }
                                    alt=""
                                />
                            )}
                        </span>
                    </div>
                    {/* error user name */}
                    <div className="text-danger text-danger__spacing">
                        <label htmlFor="error">{error?.password?.[0]}</label>
                    </div>
                    <div className="login-form__aside">
                        {/* <label className="remember">
                            <input type="checkbox" name="remember" id="remember" />
                            <span>Remember Me</span>
                        </label> */}
                        {/* <Link to='/forget-password' className="login-form__forgot">
                            Forgot Password?
                        </Link> */}
                    </div>
                    <button
                        className={
                            loading
                                ? "login-form__submited"
                                : "login-form__submit"
                        }
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? (
                            <div className={loading ? "lds-ring" : ""}>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        ) : (
                            <div>SUBMIT</div>
                        )}
                    </button>
                    <div className="text-danger text-danger__spacing">
                        <label>{error?.error}</label>
                    </div>
                </form>
            </div>
        </div>
    );
}
