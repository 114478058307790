import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import Header from "./Header";
// import Footer from "./Footer";
import ProgressBarLocal from "@contexts/progressBarContext";
import NotifyBarLocal from "@contexts/notifyBarContext";
import BroadcastEventLocal from "@contexts/broadcastEventcontext";
import { TOKEN } from "src/constants";
import Drawer from "./Drawer";

export default function App() {
    const token = Cookies.get(TOKEN);
    const location = useLocation();
    const [nav, setNav] = useState(false);
    const [hideSideBar, setHideSideBar] = useState(false);

    function checkToken() {
        if (!token && window.location.pathname !== "/login") {
            const redirect_link =
                window.location.pathname + window.location.search;

            if (window.location.pathname) {
                window.location = "/login?redirect=" + redirect_link;
            } else {
                window.location = "/login";
            }
        }
    }

    useEffect(() => {
        checkToken();
        // multiple check to hide side bar separate by OR operator
        // const regex = new RegExp(/lesson|setting/);
        const regex = new RegExp(/lesson|quiz/);
        setHideSideBar(regex.test(location.pathname));
        // eslint-disable-next-line
    }, [location]);

    function handleNavClick() {
        setNav(!nav);
    }

    return (
        <>
            <ProgressBarLocal>
                <NotifyBarLocal>
                    <BroadcastEventLocal>
                        <div className="container">
                            <div style={{ width: "100%" }}>
                                <Header toggleNav={handleNavClick} />
                                <div className="wrapper">
                                    <button
                                        className={
                                            nav
                                                ? "js-close is-active"
                                                : "js-close"
                                        }
                                        onClick={handleNavClick}
                                    />
                                    {!hideSideBar && <Drawer open={nav} />}
                                    <main>
                                        <Outlet />
                                    </main>
                                </div>
                            </div>
                            {/* <Footer/> */}
                        </div>
                    </BroadcastEventLocal>
                </NotifyBarLocal>
            </ProgressBarLocal>
        </>
    );
}
