//normal
import home from "@assets/img/menu-icon-dashboard.svg";
import setting from "@assets/img/menu-icon-settings.svg";
import recommend from "@assets/img/facetime-video.svg"
//active icon
import home_a from "@assets/img/menu-icon-dashboard-a.svg";
import setting_a from "@assets/img/menu-icon-settings-a.svg";
import recommend_a from "@assets/img/facetime-video-a.svg"
const menus = [
    {
        label: "Home",
        path: "/",
        className: "is-home",
        activePathName: "home",
        icon: home,
        icon_a: home_a,
    },
    {
        label: "Recommend Video",
        path: "recommend",
        className: "is-recommend",
        activePathName: "recommend",
        icon: recommend,
        icon_a: recommend_a,
    },
    // {
    //     label: 'Course',
    //     path: 'course',
    //     className: 'is-course'
    // },
    // {
    //     label: 'Referral',
    //     path: 'referral',
    //     className: 'is-referral'
    // },
    // {
    //     label: 'Interviews',
    //     path: 'interview',
    //     className: 'is-interviews'
    // },
    // {
    //     label: 'Certificate',
    //     path: 'certificate',
    //     className: 'is-certificate'
    // },
    // {
    //     label: 'Message',
    //     path: 'message',
    //     className: 'is-message'
    // },
    {
        label: "Settings",
        path: "settings",
        activePathName: "settings",
        className: "is-settings",
        icon: setting,
        icon_a: setting_a,
    },
];

export default menus;
