import { createSlice } from "@reduxjs/toolkit"

const quiz = createSlice({
    name: 'quiz',
    initialState: {
        quiz: {
            questions:[]
        },
    },
    reducers: {
        SET_QUIZZES(state, { payload }) {
            return {
                ...state,
                quiz: payload
            }
        },
    }
})

export const { SET_QUIZZES } = quiz.actions
export default quiz.reducer