import HomeRequest from "src/requests/homeRequest";
import { SET_LESSON_DETAILS, SET_SECTION } from ".";

export function fetchSection(payload) {

    return async (dispatch) => {
        const response = await HomeRequest.fetchSection(payload)
        dispatch(SET_SECTION(response.data))
        return response
    }
}

export function fetchLessonDetails(payload) {
    return async (dispatch) => {
        const response = await HomeRequest.fetchLessonDetails(payload)
        dispatch(SET_LESSON_DETAILS(response.data))
        return response
    }
}