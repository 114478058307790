import React from 'react'

export default function Button(state) {
    const { is_loading, button_type, handleEvent, is_disabled, name, customStyle } = state
    return (
        <>
            {is_loading ?
                <div className={is_loading ? "lds-ring" : ""}><div></div><div></div><div></div><div></div>
                </div>
                :
                <button className={customStyle ? customStyle:'loading-btn'} id={name} type={button_type} onClick={handleEvent} disabled={is_disabled}>{name}</button>
            }
        </>
    )
}
