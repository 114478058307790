import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppLayout from "@layouts/App";

import auth from "./auth";
import error from "./error";

import home from "./home";
import course from "./course";
import referral from "./referral";
import interview from "./interview";
import certificate from "./certificate";
import message from "./message";
import setting from "./setting";
import recommend from "./recommend";
import { AuthenticateRoute } from "./middleware";

export default function AppRoute() {
    return (
        <BrowserRouter>
            <Routes>
                {auth}
                <Route
                    path="/"
                    element={
                        <AuthenticateRoute>
                            <AppLayout />
                        </AuthenticateRoute>
                    }
                >
                    {home}
                    {course}
                    {referral}
                    {interview}
                    {message}
                    {setting}
                    {recommend}
                </Route>
                {error}
                {certificate}
            </Routes>
        </BrowserRouter>
    );
}
