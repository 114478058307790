import Cookies from "js-cookie";
import React from "react";
import { useEffect } from "react";
import { useContext, useState, createContext } from "react";
import { TOKEN } from "src/constants";

const broadcastEventcontext = createContext({});
export const useBroadcastEventcontext = () => useContext(broadcastEventcontext);
export default function BroadcastEventLocal({ children }) {
    const token = Cookies.get(TOKEN);
    const [messageEvent, setMessageEvent] = useState({});
    const path = `ws/courses/?token=${token}`;
    useEffect(() => {
        const socket = new WebSocket(process.env.REACT_APP_WSS_BASE_URL + path);
        socket.onopen = (evt) => {
            //initial connect to serverI
        };
        socket.onmessage = (evt) => {
            setMessageEvent(JSON.parse(evt.data));
        };
        socket.onerror = (evt) => {};
        socket.onclose = (evt) => {
            // console.log("The connection has been closed successfully.", evt)
            // socket.close(1000,'close connection')
        };
        // close connect by front
        // () => {
        //     socket.close(1000, "Hello")
        // }
        // eslint-disable-next-line
    }, []);

    return (
        <broadcastEventcontext.Provider value={{ messageEvent }}>
            {children}
        </broadcastEventcontext.Provider>
    );
}
