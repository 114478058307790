import ProgressBar from "@ramonak/react-progress-bar";
import React, { createContext, useContext, useState } from "react";

const progressBarContext = createContext(0);
export const useProgressBarContext = () => useContext(progressBarContext);

export default function ProgressBarLocal({ children }) {
    const [progress, setProgress] = useState(0);

    function showProgress() {
        setProgress(80);
    }
    function completeProgress() {
        setProgress(100);
        setTimeout(() => {
            setProgress(0);
        }, 1000);
    }

    return (
        <progressBarContext.Provider
            value={{
                showProgress,
                completeProgress,
            }}
        >
            {!!progress && (
                <ProgressBar
                    className="progress-bar"
                    borderRadius="0"
                    height="0.2rem"
                    completed={progress}
                    animateOnRender={true}
                    isLabelVisible={false}
                    bgColor="#018f91"
                />
            )}
            {children}
        </progressBarContext.Provider>
    );
}
