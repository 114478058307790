import { VideoEmbedded } from "@components/index";
import { isEmpty } from "@utils/common";
import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import recommendVideoRequest from "src/requests/recommendVideoRequest";
import CategoryVideoList from "./CategoryVideoList";

export default function CategoryVideoDetail() {
    const { category } = useParams();
    const [categoryVideoList, setCategoryVideoList] = useState({});
    const [videoDetail, setVideoDetail] = useState();
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const [videoId, setVideoId] = useState(null);
    const [categoryTitle, setCategoryTitle] = useState("");

    useEffect(() => {
        setVideoDetail(null);
        fetchVideoDetail(category);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category]);

    async function fetchVideoDetail(id) {
        // fetch category video detail
        try {
            const resultVideo = await recommendVideoRequest.getVideoDetail(id);
            setVideoId(resultVideo?.data?.video_id);
            setVideoDetail(resultVideo?.data);
            //update category title
            setCategoryTitle(resultVideo?.data?.category.name);

            // get category video list
            if (isEmpty(categoryVideoList)) {
                fetchVideoCategory(
                    resultVideo?.data?.category.id,
                    searchParams.get("recommend")
                );
            }
        } catch {
            console.log("Error");
        }
    }

    async function fetchVideoCategory(id, recommend = false) {
        const isTrue = recommend === "true";
        try {
            if (isTrue) {
                const resultCategoryList =
                    await recommendVideoRequest.getRecommendCategoryList();
                setCategoryVideoList({
                    results: resultCategoryList?.data?.results,
                });
            } else {
                const resultCategory =
                    await recommendVideoRequest.getCategoryList(id);
                setCategoryVideoList(resultCategory?.data);
            }
        } catch {
            console.log("Error");
        }
    }

    return (
        <>
            <div className="recommend-detail">
                <div className="recommend-detail__header">
                    <label htmlFor="">All Videos</label>
                </div>
                <div className="recommend-detail__main">
                    <div className="recommend-detail__main-video">
                        {videoId && (
                            <VideoEmbedded
                                videoId={videoId}
                                customStyle="yt-video-mini"
                            />
                        )}
                        {!!categoryTitle && !videoId && (
                            <label>Something went wrong</label>
                        )}

                        {!!videoDetail?.description && (
                            <div className="recommend-detail__main-descriptions">
                                <label htmlFor="">
                                    {videoDetail?.description}
                                </label>
                            </div>
                        )}
                    </div>
                    <div className="recommend-detail__main-aside">
                        <div className="recommend-detail__main-aside__ttl">
                            <label htmlFor="">
                                {searchParams.get("recommend") === "true"
                                    ? "Recommended Videos"
                                    : categoryTitle}
                            </label>
                        </div>
                        <CategoryVideoList
                            categoryName="Job Support"
                            categoryVideoList={categoryVideoList}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
