import Button from "@components/button/Button";
import { useNotifyBarContext } from "@contexts/notifyBarContext";
import { fetchQuizDetails, startQuiz } from "@stores/quiz/thunk";
import { formatDate } from "@utils/common";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import transcript from "src/requests/transcript";

export default function Instruction({ data }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { quizId } = useParams();

    //notify
    const { showNotify } = useNotifyBarContext();

    //local prefix
    const {
        title,
        duration,
        instructions,
        total_marks,
        is_submitted,
        result_type,
        message,
        result_score,
    } = data;

    //handle coundown and reload after quiz is expired
    async function handleStartQuiz() {
        try {
            setLoading(true);
            await dispatch(startQuiz(quizId));
            await dispatch(fetchQuizDetails(quizId));
        } catch (e) {
            showNotify("error", "Activate quiz failed");
            setLoading(false);
        }
    }

    async function handleDownload() {
        try {
            const file = await transcript.downloadTranscript(quizId);
            var link = document.createElement("a");
            var binaryData = [];
            binaryData.push(file.data);
            link.href = window.URL.createObjectURL(
                new Blob(binaryData, { type: "application/pdf" })
            );
            link.download = "Transcript_" + formatDate(new Date());
            link.click();
        } catch (error) {
            console.log("error");
        }
    }

    return (
        <div className="quiz__wrap">
            <div className="quiz-instruction">
                <div className="title">
                    <h3 htmlFor="title">
                        <label>{title ?? "..."}</label>
                    </h3>
                    {result_type === "passed" && (
                        <div>
                            <button
                                className="settings-files__dl"
                                onClick={handleDownload}
                            >
                                Download Transcript
                            </button>
                        </div>
                    )}
                </div>
                <hr className="style-hr" />
                <div>
                    <h3 htmlFor="">Time Limit : {duration + "m"}</h3>
                    <h3>Total Point: {total_marks}</h3>
                </div>
                <br />
                <p>{instructions ?? "..."}</p>
                <br />
                {/* modify after api  completed json message with status pass or fail */}
                <div className={`quiz-result ${result_type}`}>
                    <div>{message}</div>
                    <br />
                    <>
                        {!!result_type && (
                            <div>
                                {" "}
                                {result_type} {result_score} /{total_marks}
                            </div>
                        )}
                    </>
                </div>
                {!is_submitted && !data?.remaining && (
                    <>
                        <div
                            className={loading ? "loading-btn" : ""}
                            disabled={loading}
                        >
                            <Button
                                is_loading={loading}
                                button_type="submit"
                                is_disabled={false}
                                name="START"
                                handleEvent={handleStartQuiz}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
