import React from 'react';
import gameController from '@assets/img/gaming.png';
import diversity from "@assets/img/diversity.png";
import binoculars from "@assets/img/binoculars.png";

export default function Virtues(){
  return (
    <div className="virtues__container">
      <h1 className="virtues__title">TECH I.S. Virtues</h1>
      <div className="virtues__card">
        <img className="virtues__icon" src={gameController} />
        <div className="virtues__content">
          <h2 className="virtues__heading">Enjoy Coding</h2>
          <p className="virtues__text">Coding is just like a game. Enjoy it!</p>
        </div>
      </div>
      <div className="virtues__card">
        <img className="virtues__icon" src={diversity} />
        <div className="virtues__content">
          <h2 className="virtues__heading">Be a Team Player</h2>
          <p className="virtues__text">
            Every engineer in the world is one team.
          </p>
        </div>
      </div>
      <div className="virtues__card">
        <img className="virtues__icon" src={binoculars} />
        <div className="virtues__content">
          <h2 className="virtues__heading">Be Professional</h2>
          <p className="virtues__text">Turn problems into opportunities.</p>
        </div>
      </div>
    </div>
  );
}
