import { useEffect, useState } from "react";
import Body from "./layouts/Body";
import Header from "./layouts/Header";
import badge from "src/requests/badge";
import { useParams } from "react-router-dom";
import Error404 from "@features/error/Error404";
import { CRITERIA } from "./constants/constants";
import { isEmpty } from "@utils/common";
import VerificationPopUp from "./VerificationPopUp";
import Popup from "reactjs-popup";

export default function Certificate() {
    const { badgeuuid } = useParams();
    const [studentInfo, setStudentInfo] = useState({});
    const [isActive, setIsActive] = useState(false);
    const [error, setError] = useState(false);
    useEffect(() => {
        fetchStudentInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [badgeuuid]);
    async function fetchStudentInfo() {
        try {
            const result = await badge.findBadge(badgeuuid);
            // check if the student course is not main course
            CRITERIA.map((el) => {
                if (result?.data.main_course.slug === el.main_course) {
                    setStudentInfo({ ...result.data, extra: el });
                }
                return el;
            });
        } catch (error) {
            setError(true);
        }
    }
    const contentStyle = { background: "transparent", border:'unset' };
    const overlayStyle = { background: "rgba(0,0,0,0.5)" };
    const arrowStyle = { color: "#000" }; // style for an svg element

    return (
        <div>
            {error || isEmpty(studentInfo) ? (
                <Error404 />
            ) : (
                <>
                    <Header data={studentInfo} handleBadgeClick={setIsActive} />
                    <Body data={studentInfo} />
                    <Popup
                        open={isActive}
                        modal
                        onClose={() => setIsActive(false)}
                        closeOnDocumentClick={true}
                         {...{ contentStyle, overlayStyle, arrowStyle }}
                    >
                        <VerificationPopUp
                            data={studentInfo}
                            handleBadgeClick={setIsActive}
                        />
                    </Popup>
                </>
            )}
        </div>
    );
}
