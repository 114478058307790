import PDFIcon from "@assets/img/settings-icon-pdf.png";
import CertificateIcon from "@assets/img/certificate_icon.png";
import { formatDate } from "@utils/common";

export default function DownloadFile({ user }) {
    const resume = user?.resume_url;
    const cover_letter = user?.cover_letter_url;
    const completion_certificate = user?.certificate_badge_url;
    const attendance_certificate = user?.attendance_certificate_url;
    const badgeuuid = user?.badge_uuid;

    function handleDownload(url, name) {
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                var link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = name + "_" + formatDate(new Date());
                link.style.display = "none";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
    }

    return (
        <>
            <aside className="settings-files">
                <h2 className="sec-ttl">Download Your Files</h2>
                <ul className="settings-files__list">
                    <li className="settings-files__item">
                        <div className="settings-files__thumb">
                            <img src={PDFIcon} alt="" />
                        </div>
                        <p className="settings-files__name">Resume</p>
                        <button
                            className={`${
                                resume
                                    ? "settings-files__dl"
                                    : "settings-files__dl-disabled"
                            }`}
                            disabled={resume === null}
                            onClick={() =>
                                handleDownload(user?.resume_url, "resume")
                            }
                        >
                            DOWNLOAD
                        </button>
                    </li>
                    <li className="settings-files__item">
                        <div className="settings-files__thumb">
                            <img src={PDFIcon} alt="" />
                        </div>
                        <p className="settings-files__name">Cover Letter</p>
                        <button
                            className={`${
                                cover_letter
                                    ? "settings-files__dl"
                                    : "settings-files__dl-disabled"
                            }`}
                            disabled={cover_letter === null}
                            onClick={() =>
                                handleDownload(
                                    user?.cover_letter_url,
                                    "cover_letter"
                                )
                            }
                        >
                            DOWNLOAD
                        </button>
                    </li>
                    <li className="settings-files__item">
                        <div className="settings-files__thumb">
                            <img src={PDFIcon} alt="" />
                        </div>
                        <p className="settings-files__name">
                            Completion Certificate
                        </p>
                        <button
                            onClick={() =>
                                handleDownload(
                                    user?.certificate_badge_url,
                                    "completion_certificate"
                                )
                            }
                            className={`${
                                completion_certificate
                                    ? "settings-files__dl"
                                    : "settings-files__dl-disabled"
                            }`}
                            disabled={completion_certificate === null}
                        >
                            DOWNLOAD
                        </button>
                    </li>
                    <li className="settings-files__item">
                        <div className="settings-files__thumb">
                            <img src={PDFIcon} alt="" />
                        </div>
                        <p className="settings-files__name">
                            Attendence Certificate
                        </p>
                        <button
                            className={`${
                                attendance_certificate
                                    ? "settings-files__dl"
                                    : "settings-files__dl-disabled"
                            }`}
                            disabled={attendance_certificate === null}
                            onClick={() =>
                                handleDownload(
                                    user?.attendance_certificate_url,
                                    "attendence_certificate"
                                )
                            }
                        >
                            DOWNLOAD
                        </button>
                    </li>
                    <li className="settings-files__item">
                        <div className="settings-files__thumb">
                            <img src={CertificateIcon} alt="" />
                        </div>
                        <p className="settings-files__name">
                            Certificate Badge
                        </p>
                        <button
                            className={`${
                                badgeuuid
                                    ? "settings-files__dl"
                                    : "settings-files__dl-disabled"
                            }`}
                            disabled={badgeuuid === null}
                            onClick={() =>
                                window.open(`/badge/${badgeuuid}`, "")
                            }
                        >
                            VIEW
                        </button>
                    </li>
                </ul>
            </aside>
        </>
    );
}
