import BaseRequest from "./baseRequest";

class QuizRequest extends BaseRequest {
    startQuiz(id) {
        return this.performRequest(BaseRequest.METHOD_POST, `quizzes/start/${id}`)
    }
    fetchQuizDetails(id) {
        return this.performRequest(BaseRequest.METHOD_GET, `quizzes/find/${id}`)
    }
    sendQuiz(id, payload) {
        return this.performRequest(BaseRequest.METHOD_POST, `quizzes/send/${id}`, payload)
    }
    submitQuiz(id) {
        return this.performRequest(BaseRequest.METHOD_PUT, `quizzes/submit/${id}`)
    }
}

export default new QuizRequest('')
