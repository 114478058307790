import { Profile } from "@components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { updateUserProfile } from "@stores/auth/thunk";
import { InputSubmit } from "@components/";
import DownloadFile from "./components/DownloadFile";
import { useNotifyBarContext } from "@contexts/notifyBarContext";

export default function List() {
    const { user } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const [form, setForm] = useState({ linkedin_link: '', portfolio_link: '' })
    const [isEdit, setEdit] = useState({ linkedin_link: false, portfolio_link: false })
    const { showNotify } = useNotifyBarContext()

    //edit type
    const defaultValue = '...'


    useEffect(() => {
        setForm((prev) => ({
            ...prev,
            linkedin_link: user?.linkedin_link,
            portfolio_link: user?.portfolio_link
        }
        ))
    }, [user])

    function handleForm(event) {
        const { name, value } = event.target;
        setForm(prev => ({
            ...prev,
            [name]: value
        }));
    }

    function handleFormClose(name) {
        setEdit(prev => ({
            ...prev,
            [name]: !isEdit[name]
        }));
        dispatch(updateUserProfile(form))
        showNotify('success')
    }

    return (
        <div className="settings">
            <div className="contents">
                <main className="settings-main">
                    <section className="settings-profile">
                        <h2 className="sec-ttl">Student Details</h2>
                        <Profile isUploadable />
                    </section>
                    <section className="settings-pro">
                        <h2 className="sec-ttl">Professional Details</h2>
                        <ul className="settings-pro__list">
                            <li className="settings-pro__item">
                                <div className="settings-pro__thumb"><img src={require("@assets/img/settings-icon-linkedin.svg").default} alt="" decoding="async" /></div>
                                <div className="settings-pro__contents">
                                    <span className={`${isEdit.linkedin_link ? '' : 'edit'}`} onClick={() => setEdit((prev) => ({ ...prev, linkedin_link: !prev.linkedin_link }))}></span>
                                    <h3 className="settings-pro__subttl">Linkedin Profile</h3>
                                    {isEdit.linkedin_link ?
                                        <InputSubmit
                                            name="linkedin_link"
                                            value={form.linkedin_link}
                                            handleChange={handleForm}
                                            handleClose={handleFormClose}
                                            rules="link"
                                            errorMessage="Invalid Url" /> :
                                        <a
                                            href={user?.linkedin_link}
                                            className="settings-pro__content">
                                            {user.linkedin_link ?? defaultValue}
                                        </a>
                                    }
                                </div>
                            </li>
                            <li className="settings-pro__item">
                                <div className="settings-pro__thumb"><img src={require("@assets/img/settings-icon-portfolio.svg").default} alt="" decoding="async" /></div>
                                <div className="settings-pro__contents">
                                    <span className={`${isEdit.portfolio_link ? '' : 'edit'}`} onClick={() => setEdit((prev) => ({ ...prev, portfolio_link: !prev.portfolio_link }))}></span>
                                    <h3 className="settings-pro__subttl">Portfolio Link</h3>
                                    {isEdit.portfolio_link ?
                                        <InputSubmit
                                            name="portfolio_link"
                                            value={form.portfolio_link}
                                            handleChange={handleForm}
                                            handleClose={handleFormClose}
                                            rules="link"
                                            errorMessage="Invalid Url" /> :
                                        <a
                                            href={user?.portfolio_link}
                                            className="settings-pro__content">
                                            {user.portfolio_link ?? defaultValue}
                                        </a>
                                    }
                                </div>
                            </li>
                            <li className="settings-pro__item">
                                <div className="settings-pro__thumb"><img src={require("@assets/img/settings-icon-status.svg").default} alt="" decoding="async" /></div>
                                <div className="settings-pro__contents">
                                    <h3 className="settings-pro__subttl">Status</h3>
                                    <p className="settings-pro__content">{user?.sub_status?.name}</p>
                                </div>
                            </li>
                            <li className="settings-pro__item">
                                <div className="settings-pro__thumb"><img src={require("@assets/img/settings-icon-course.svg").default} alt="" decoding="async" /></div>
                                <div className="settings-pro__contents">
                                    <h3 className="settings-pro__subttl">Course</h3>
                                    {/* display multiple course */}
                                    {
                                        user?.courses?.map((el,index)=>{
                                            return <span key={index}>{el.name}{user?.courses.length-1 > index ? ', ':''}</span>
                                        })
                                    }
                                </div>
                            </li>
                            <li className="settings-pro__item">
                                <div className="settings-pro__thumb"><img src={require("@assets/img/settings-icon-avatar.svg").default} alt="" decoding="async" /></div>
                                <div className="settings-pro__contents">
                                    <h3 className="settings-pro__subttl">Cohort Handler Name</h3>
                                    <p className="settings-pro__content">{user?.cohort?.name}</p>
                                </div>
                            </li>
                        </ul>
                    </section>
                </main>
                <DownloadFile user={user} />
            </div>
        </div >
    )
}