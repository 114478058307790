import axios from "axios";
import Cookies from "js-cookie";
import { TOKEN } from "src/constants";
import BaseResponse from "src/responses/base-response";

const http = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL ?? "http://127.0.0.1:8000/s/",
    headers: {
        "Content-Type": "application/json",
    },
});

http.interceptors.request.use(
    (config) => {
        const token = Cookies.get(TOKEN);
        config.headers.common["Authorization"] = token;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

http.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const response = new BaseResponse({
            data: error.response.data,
            statusCode: error.response.status,
        });

        if (response.statusUnprocessableEntity()) {
            return Promise.reject(response.getData());
        } else if (response.statusUnauthorize()) {
            Cookies.remove(TOKEN);
            if (window.location.pathname !== "/login") {
                const redirect_link =
                    window.location.pathname + window.location.search;
                if (window.location.pathname) {
                    window.location = "/login?redirect=" + redirect_link;
                }
            }
            return Promise.reject(response.getData());
        } else if (response.statusForbidden()) {
            Cookies.remove(TOKEN);
            window.location = "/login";
            return Promise.reject(response.getData());
        } else if (response.statusNotFound()) {
            return Promise.reject(response.getData());
        } else if (response.statusInternalServerError()) {
            return Promise.reject(response.getData());
        }

        return Promise.reject(error);
    }
);

export default http;
