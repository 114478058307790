import React, { useEffect, useState } from "react";

export default function CustomSelect({
  option,
  selectedValueParent,
  onChange,
}) {
  const [nav, setNav] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(selectedValueParent);

  useEffect(() => {
    document.addEventListener("click", handleClickOutSide, true);
    setSelectedLabel(selectedValueParent);
    //eslint-disable-next-line
  }, [selectedValueParent]);
  function toggleNav() {
    setNav(!nav);
  }

  function handleClickOutSide(e) {
    if (
      !e.target.closest(".main-header__popup") &&
      !e.target.closest(".main-header__select")
    ) {
      setNav(false);
    }
  }

  function onClick(e, element) {
    e.preventDefault();
    setSelectedLabel(element.name);
    onChange(element);
  }

  return (
    <div className="main-header__select" onClick={toggleNav}>
      <span className="label">{selectedLabel}</span>
      <ul
        className={nav ? "main-header__popup is-active" : "main-header__popup"}
      >
        <li className="main-header__popup-item-item">
          <span>{selectedLabel}</span>
        </li>
        {option?.map(
          (el, index) =>
            el.name !== selectedLabel && (
              <li
                key={index}
                className="main-header__popup-item-item"
                onBlurCapture={handleClickOutSide}
                onClick={(e) => onClick(e, el)}
              >
                <span>{el.name}</span>
              </li>
            )
        )}
      </ul>
    </div>
  );
}
