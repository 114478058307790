import BaseRequest from "./baseRequest";

class ActivityUserRequest extends BaseRequest {
    sendLogUser(payload) {
        return this.performRequest(
            BaseRequest.METHOD_POST,
            "activity/",
            payload
        );
    }
}

export default new ActivityUserRequest('courses')
