import ActivityUserRequest from "src/requests/activityUserRequest";
import { SET_USER_ACTIVITY } from ".";

export function setUserActivity(payload) {
    return (dispatch) => {
        dispatch(SET_USER_ACTIVITY(payload));
    };
}

export function createUserActivity(payload){
    return (dispatch) =>{
        return ActivityUserRequest.sendLogUser(payload)
    }
}
