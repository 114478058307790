import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSection } from "@stores/home/thunk";
//context
import { useBroadcastEventcontext } from "@contexts/broadcastEventcontext";
import { useNotifyBarContext } from "@contexts/notifyBarContext";

//var
import { EVENT_TYPE, TECHIS_USER_ACTIVITY } from "src/constants";
import {
    clearToken,
    getCurrentCourseState,
    getLocalStorageLog,
} from "@utils/common";
import createActivityDetector from "activity-detector";
//icon
import techisIcon from "@assets/img/login-techis.svg";
import logoutIcon from "@assets/img/arrow-right-from-bracket-solid.svg";
import userIcon from "@assets/img/user-solid.svg";
import avatar from "@assets/img/sample-avatar.png";
import { getCurrentUser } from "@stores/auth/thunk";
import { SET_USER_ACTIVITY } from "@stores/activity";
import { createUserActivity, setUserActivity } from "@stores/activity/thunk";

export default function Header({ toggleNav }) {
    const { messageEvent } = useBroadcastEventcontext();
    const { showNotify } = useNotifyBarContext();
    const data = useSelector((state) => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isActive, setIsActive] = useState(false);

    const activityDetector = createActivityDetector({
        //60 minutes idle -> no activity
        timeToIdle: 600000,
        inactivityEvents: ["visibilitychange"],
        initialState: "idle",
    });

    useEffect(() => {
        if (
            messageEvent.type === EVENT_TYPE.LESSON ||
            messageEvent.type === EVENT_TYPE.QUIZ
        ) {
            const currentLesson = JSON.parse(getLocalStorageLog());
            dispatch(fetchSection(currentLesson.slug));
            showNotify("other", `New ${messageEvent.type} activated`);
        }
        // eslint-disable-next-line
    }, [messageEvent]);

    useEffect(() => {
        const course = getCurrentCourseState();
        getUser().then((user) => {
            if (!course) {
                dispatch(setUserActivity({ course: user?.courses[0] }));
            } else {
                dispatch(setUserActivity({ course: course }));
            }
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const intervalTime =
            (process.env.REACT_APP_INTERVAL_TIME_MINUTE ?? 5) * 60000;

        const timer = setInterval(() => {
            monitorActivity();
            sendLogToBackEnd(data?.activity, window.location.pathname);
        }, intervalTime);

        return () => {
            clearInterval(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.activity]);

    async function getUser() {
        const result = await dispatch(getCurrentUser());
        return result;
    }

    function handleClickOutSide(e) {
        if (
            !e.target.closest(".dropdown") &&
            !e.target.closest(".submenu__account-avatar")
        ) {
            setIsActive(false);
        }
    }

    function monitorActivity() {
        activityDetector.on("idle", () => {
            dispatch(
                SET_USER_ACTIVITY({
                    tab_status: 0,
                })
            );
        });

        activityDetector.on("active", () => {
            dispatch(
                SET_USER_ACTIVITY({
                    tab_status: 1,
                })
            );
        });
    }

    async function sendLogToBackEnd(info, pathName) {
        const userLocalActivity = JSON.parse(
            localStorage.getItem(TECHIS_USER_ACTIVITY)
        );
        const tempData = {
            ...info,
            page: pathName,
            lesson: info?.lesson ?? userLocalActivity?.lesson,
            course: info?.course?.id ?? userLocalActivity?.course.id,
            section:
                info?.section?.section ?? userLocalActivity?.section?.section,
            tab_status: info?.tab_status ?? 1,
        };
        try {
            await dispatch(createUserActivity(tempData));
        } catch (error) {
            // console.log(error.response.data?.detail);
        }
    }

    return (
        <header className="header">
            <div className="header__wrapper">
                {!window.location.pathname.includes("lesson") && (
                    <div className="submenu__list is-nav" onClick={toggleNav} />
                )}
                <a className="header__icon" href="/">
                    <img src={techisIcon} alt="techis" />
                </a>
            </div>
            <div className="submenu">
                <ul className="submenu__menu">
                    <li
                        className="submenu__account"
                        onClick={() => setIsActive(!isActive)}
                        onBlurCapture={handleClickOutSide}
                        tabIndex={0}
                    >
                        <div className="dropdown">
                            <div className="submenu__account-avatar">
                                <img
                                    src={data.auth.user.profile ?? avatar}
                                    width="52"
                                    height="52"
                                    alt=""
                                    decoding="async"
                                />
                            </div>
                            <p className="submenu__account-name">
                                {data.auth.user.name}
                            </p>
                        </div>
                        <div
                            className={`dropdown-content ${
                                isActive ? "is-dropdown" : ""
                            }`}
                        >
                            <div>
                                <img src={userIcon} alt="setting" />
                                <label
                                    htmlFor="/settings"
                                    onClick={() =>
                                        navigate("/settings", { replace: true })
                                    }
                                >
                                    Profile
                                </label>
                            </div>
                            <div>
                                <img src={logoutIcon} alt="logout" />
                                <label onClick={() => clearToken()}>
                                    Log out
                                </label>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </header>
    );
}
