import { Route } from "react-router-dom";

import Login from "@features/auth/Login";
// import Register from "@features/auth/Register";
// import ForgetPassword from "@features/auth/ForgetPassword";


const routes = [
    <Route key="login" path="/login" element={<Login />} />,
    // <Route key="register" path="/register" element={ <Register/> }/>,
    // <Route key="forget-password" path="/forget-password" element={ <ForgetPassword/> }/>
]

export default routes
