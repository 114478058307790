export default function Quiz() {

    return (
        <div>
            <div className="main">
                <div className="main-header">
                    <h2 className="main-header__ttl">Quiz</h2>
                </div>
            </div>

            <div className="quiz">
                <ul className="questions">
                    <Question/>
                    <Question/>
                    <Question/>
                    <Question/>
                    <Question/>
                </ul>
                <div className="action">
                    <button className="submit" type="button">SUBMIT</button>
                </div>
            </div>
        </div>
    )
}

function Question() {

    return (
        <li className="question-item">
            <div className="item-header">
                <div className="description">
                    Q-1) HTML uses:
                </div>
                <div className="point">
                    <span className="score">
                        1
                    </span>
                    <img className="img" src={require("@assets/img/point.svg").default} alt="" />
                    POINT
                </div>
            </div>
            <div className="question-item-img">
                <div className="quiz-img">
                    <img src={require("@assets/img/login-catch.png")} alt="" />
                </div>
            </div>
            <div className="answers">
                <ul>
                    <li className="answer-item">
                        <div className="checkbox">
                            <input type="checkbox" name="checkbox"/>
                        </div>
                        <div className="answer">
                            <div>
                                User defined tags
                            </div>
                            <div className="quiz-img">
                                <img src={require("@assets/img/login-catch.png")} alt="" />
                            </div>
                        </div>
                    </li>
                    <li className="answer-item">
                        <div className="checkbox">
                            <input type="checkbox" name="checkbox"/>
                        </div>
                        <div className="answer">
                            <div>
                                User defined tags
                            </div>
                            <div className="quiz-img">
                                <img src={require("@assets/img/login-catch.png")} alt="" />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </li>
    )
}