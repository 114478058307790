import RecommendVideoRequest from "src/requests/recommendVideoRequest";
import { SET_RECOMMEND_VIDEO } from ".";

export function getRecommendedVideos() {
    return async (dispatch) => {
        const results = await RecommendVideoRequest.getRecommendVideoList();
        dispatch(SET_RECOMMEND_VIDEO(results?.data));
    };
}

export function getCategoryLoadMore(categoryFilter) {
    return async (dispatch) => {
        const results = await RecommendVideoRequest.getLoadMoreCategory(
            categoryFilter
        );
        return results
    };
}
