import React, { useState } from "react";
import RecommendItem from "./RecommendItem";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryLoadMore } from "@stores/recommend/thunk";

// icon
import loadMoreIcon from "@assets/img/load_more_icon.png";
import {
    SET_RECOMMEND_VIDEO_CATEGORY,
    SET_RECOMMEND_VIDEO_LOAD_MORE,
} from "@stores/recommend";
import recommendVideoRequest from "src/requests/recommendVideoRequest";
import { isMobileDevice } from "@utils/common";
import { Button } from "@components/index";

export default function RecommendSection(props) {
    const { data, is_recommend, categoryId } = props;
    const { categories, recommend_videos } = useSelector(
        (state) => state.recommend_video
    );
    const [disabledLoadMoreCategories, setDisabledLoadMoreCategories] =
        useState([]);
    const [filter, setFilter] = useState({
        id: categoryId,
        page: 2,
    });
    const dispatch = useDispatch();
    function handleClickMore() {
        switch (is_recommend) {
            case true:
                handelCLickMoreRecommend();
                break;
            case false:
                handleClickMoreCategory();
                break;
            default:
                console.log("unknown case");
                break;
        }
    }

    async function handleClickMoreCategory() {
        try {
            const results = await dispatch(getCategoryLoadMore(filter));
            const newVideo = results?.data?.results;
            setFilter((prev) => ({ ...prev, page: prev.page + 1 }));
            // find the correct category then append new data
            const video = categories?.results?.map((category) => {
                if (category.id === categoryId) {
                    let oldVideo = [];
                    oldVideo = category?.videos?.concat(newVideo);
                    return { ...category, videos: oldVideo };
                }
                return category;
            });

            dispatch(
                SET_RECOMMEND_VIDEO_CATEGORY({
                    payload: video,
                })
            );
        } catch (error) {
            handleFetchMoreError();
        }
    }

    async function handelCLickMoreRecommend() {
        try {
            const results = await recommendVideoRequest.getLoadMoreRecommend(
                filter.page
            );
            setFilter((prev) => ({ ...prev, page: prev.page + 1 }));
            // get old results then merge with new video then return new array
            const oldVideo = recommend_videos.results;
            const newVideo = results?.data?.results;
            const tempData = oldVideo.concat(newVideo);

            dispatch(
                SET_RECOMMEND_VIDEO_LOAD_MORE({
                    recommend_videos: tempData,
                })
            );
        } catch {
            handleFetchMoreError();
        }
    }

    function handleFetchMoreError() {
        // disable load more button
        setDisabledLoadMoreCategories((prev) => {
            if (prev.length > 0 && !prev.includes(categoryId)) {
                return [...prev, categoryId];
            } else {
                return [categoryId];
            }
        });
    }

    return (
        <div>
            <div className="recommend-section">
                {data?.results?.map((item, index) => (
                    <RecommendItem
                        props={item}
                        key={index}
                        is_recommend={is_recommend}
                    />
                ))}
                {!disabledLoadMoreCategories.includes(categoryId) && (
                    <span
                        className="recommend-section__load-more"
                        onClick={handleClickMore}
                    >
                        {isMobileDevice() ? (
                            <Button
                                name="Load More"
                                customStyle="load-more-button"
                            />
                        ) : (
                            <div>
                                <img src={loadMoreIcon} alt="load more" />
                            </div>
                        )}
                    </span>
                )}
            </div>
        </div>
    );
}
