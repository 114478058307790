import BaseRequest from "./baseRequest";

class AuthRequest extends BaseRequest {
    login(payload) {
        return this.performRequest(BaseRequest.METHOD_POST, 'login', payload)
    }
    getUser() {
        return this.performRequest(BaseRequest.METHOD_GET, 'profile')
    }
    updateUser(payload) {
        let form = new FormData();

        for (const [key, value] of Object.entries(payload)) {
            if (key === 'file') {
                form.append('profile', payload.file, payload.file.name)
            } else if (key && value) {
                form.append(key, value)
            }
        }

        return this.performRequest(BaseRequest.METHOD_PUT, 'update', form)
    }
}

export default new AuthRequest('auth')