import { createSlice } from "@reduxjs/toolkit";

const home = createSlice({
    name: "home",
    initialState: {
        section: {},
        lessonDetails: {},
    },
    reducers: {
        SET_SECTION(state, { payload }) {
            return {
                ...state,
                section: payload,
            };
        },
        SET_LESSON_DETAILS(state, { payload }) {
            return {
                ...state,
                lessonDetails: payload,
            };
        },
    },
});

export const { SET_SECTION, SET_LESSON_DETAILS } = home.actions;
export default home.reducer;
