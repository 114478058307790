import BaseRequest from "./baseRequest";

class RecommendVideoRequest extends BaseRequest {
    getRecommendVideoList() {
        // from page of recommend video
        return this.performRequest(BaseRequest.METHOD_GET, "");
    }
    getRecommendCategoryList() {
        return this.performRequest(
            BaseRequest.METHOD_GET,
            `videos?is_recommend=true`
        );
    }
    getCategoryList(id) {
        // aside list of all the category video
        return this.performRequest(
            BaseRequest.METHOD_GET,
            `videos/?category=${id}`
        );
    }
    getLoadMoreCategory(filters) {
        return this.performRequest(
            BaseRequest.METHOD_GET,
            `videos?category=${filters.id}&limit=3&page=${filters.page}`
        );
    }

    getLoadMoreRecommend(pageId) {
        return this.performRequest(
            BaseRequest.METHOD_GET,
            `videos?is_recommend=true&limit=3&page=${pageId}`
        );
    }

    getVideoDetail(id) {
        //for view the video
        return this.performRequest(BaseRequest.METHOD_GET, `videos/${id}`);
    }
}

export default new RecommendVideoRequest("recommended-videos");
