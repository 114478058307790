import { Route } from "react-router-dom";
import Home from "@features/home/Home";
import Content from "@features/home/components/content/Content";
import Quiz from "@features/home/components/quiz";

const routes = [
    <Route key={"home"} path="/" element={<Home />} />,
    <Route key={"lesson"} path="/lesson/:lessonId" element={<Content />} />,
    <Route key={"quiz"} path="/quiz/:quizId" element={<Quiz />} />,
]

export default routes