import Cookies from "js-cookie";
import moment from "moment";
import { TOKEN } from "src/constants";

const TECHIS_CURRENT_COURSE = "TECHIS_CURRENT_COURSE";

export const formatDate = (date) => {
    if (!date) return null;
    return moment(date).format("DD/MM/YYYY");
};

export const formatDateSpace = (date) => {
    if (!date) return null;
    return moment(date).format("MMMM DD, YYYY");
};

export const dateDiff = (date) => {
    let now = moment();
    let dateDiff = moment(date);
    return dateDiff.diff(now, "days");
};

export const isEmpty = (obj) => {
    return obj && Object.keys(obj).length === 0;
};

export const clearToken = () => {
    if (window.location !== "/login") {
        window.location = "/login";
    }
    return Cookies.remove(TOKEN);
};

export const setLocalStorageLog = (value) => {
    //set for current course
    localStorage.setItem(TECHIS_CURRENT_COURSE, value);
};

export const getLocalStorageLog = (value) => {
    return localStorage.getItem(TECHIS_CURRENT_COURSE);
};

export const updateCurrentCourseState = (value) => {
    const transformValue = {
        id: value.id,
        name: value?.name,
        slug: value?.slug,
    };
    return localStorage.setItem(
        TECHIS_CURRENT_COURSE,
        JSON.stringify(transformValue)
    );
};

export const getCurrentCourseState = (value) => {
    return JSON.parse(localStorage.getItem(TECHIS_CURRENT_COURSE));
};

export const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );
};
