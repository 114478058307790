import CategoryVideoDetail from "@features/recommend/category/CategoryVideoDetail";

const {
    default: RecommendVideo,
} = require("@features/recommend/RecommendVideo");
const { Route } = require("react-router-dom");

const routes = [
    <Route
        key={"recommend-list"}
        path="/recommend"
        element={<RecommendVideo />}
    />,
    <Route
        key={"recommend-category-list"}
        path="/recommend/:category"
        element={<CategoryVideoDetail />}
    />,
];

export default routes;
