import { Profile, Virtues } from "@components/";
// import defaulImage from "@assets/img/recommend-sample1.png"

export default function Aside() {

    return (
      <aside className="sidebar">
        <Profile />
        <Virtues />
      </aside>
    );
}